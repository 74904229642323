import * as React from 'react';
import { useState, useEffect, forwardRef } from 'react';
import MaterialTable from 'material-table';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// @mui
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { Box, Stack, Button, Container, Typography, CssBaseline, Divider, Dialog } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Material Table Icons Imports
import AddBox from '@mui/icons-material/AddBox';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';

// Material Table Icons Defined
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default function EditQoutation(props) {
  console.log('Props are', props);
  // ---------------------Modal-------------------------------
  const [openModal, setOpenModal] = useState(true);
  const [quotationLogs, setQuotationLogs] = useState(props.data);
  const theme = createTheme();
  const [quotations, setQuotations] = useState([]);
  useEffect(() => {
    // get token from local storage
    const storedToken = localStorage.getItem('userToken');

    const token = storedToken ? JSON.parse(storedToken) : null;

    if (token) {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };
      fetch(`https://quotationserver.aiodock.com/showQuotationLogs/${quotationLogs._id}`, {
        method: 'GET',
        headers,
      }).then((result) => {
        result.json().then((response) => {
          setQuotations(response.quotationLog);
          console.log('Response', response.quotationLog);
        });
      });
    } else {
      alert('token not found to fullfill this request');
    }
  }, []);

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };
  return (
    <>
      <Dialog fullScreen open={openModal} onClose={props.onClose}>
        <Box component="form" noValidate style={{ width: '100%' }}>
          <div style={{ position: 'sticky', top: 0 }}>
            <AppBar sx={{ position: 'relative' }}>
              <Toolbar>
                <IconButton edge="start" color="inherit" onClick={props.onClose} aria-label="close">
                  <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                  Quotation Logs
                </Typography>
                <Button type="submit" variant="outlined" color="inherit" sx={{ borderColor: '#fff' }}>
                  Update
                </Button>
              </Toolbar>
            </AppBar>
          </div>
          <Container>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <Divider fullWidth />
                <MaterialTable
                  icons={tableIcons}
                  title="Quotation Logs"
                  columns={[
                    {
                      title: 'Updated At',
                      field: 'updatedAt',
                      defaultSort: 'desc',
                      render: (rowData) => {
                        const updatedAtDate = new Date(rowData.updatedAt);
                        const formattedDate = updatedAtDate.toLocaleDateString();
                        const formattedTime = updatedAtDate.toLocaleTimeString();
                        return `${formattedDate} ${formattedTime}`;
                      },
                    },
                    {
                      title: 'Date',
                      field: 'date',
                      render: (rowData) => new Date(rowData.date).toLocaleDateString(),
                    },
                    { title: 'Module', field: 'module' },
                    { title: 'Brand', field: 'brand' },
                    { title: 'Category', field: 'category' },
                    { title: 'Height', field: 'screenHeight' },
                    { title: 'Width', field: 'screenWidth' },
                    { title: 'Screen Labour', field: 'screenLabour' },
                    { title: 'Rate', field: 'rate' },
                    { title: 'Discount', field: 'discount' },
                  ]}
                  data={quotations}
                  options={{
                    headerStyle: {
                      backgroundColor: '#2065D1',
                      color: '#FFF',
                    },
                    sorting: true,
                    search: true,
                    paging: true,
                    pageSize: 10,
                  }}
                />
              </ThemeProvider>
            </LocalizationProvider>
          </Container>
        </Box>
      </Dialog>
    </>
  );
}
