import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';
import Clients from './pages/Clients';
import Orders from './pages/Quotation';
import Calculator from './pages/Calculator';
import Modules from './pages/Modules';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import Invoice from './pages/Invoice';
import Users from './pages/Users';

// ----------------------------------------------------------------------

export default function Router() {
  const loggedIn = localStorage.getItem('user') !== false && localStorage.getItem('user') !== undefined;
  const userRole = localStorage.getItem('role');

  const routes = useRoutes([
    loggedIn
      ? {
          path: '/dashboard',
          element: <DashboardLayout />,
          children: [
            { element: <Navigate to="/dashboard/clients" />, index: true },
            { path: 'clients', element: <Clients /> },
            { path: 'quotations', element: <Orders /> },
            { path: 'calculator', element: <Calculator /> },
            { path: 'modules', element: <Modules /> },
            { path: 'invoice', element: <Invoice /> },
            userRole === 'admin' ? { path: 'users', element: <Users /> } : null,
          ].filter(Boolean), 
        }
      : { element: <Navigate to="/login" /> },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: loggedIn ? <Navigate to="/dashboard/clients" /> : <LoginPage /> },
        { path: 'login', element: <LoginPage /> },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/dashboard/clients" /> },
      ],
    },
  ]);

  return routes;
}
