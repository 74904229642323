import * as React from 'react';
import { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider, Box, Button, TextField, Grid } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function Users({ handleSuccess, handleError, handleClose, open }) {
  const theme = createTheme();

  const [phoneNumber, setPhoneNumber] = useState('');
  const [formData, setFormData] = useState({
    clientName: '',
    clientAddress: '',
    city: '',
    company: '',
    contactNo: '',
    clientDesignation: '',
  });

  // Submit Data to the Database
  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.clientName === undefined || formData.clientName.trim() === '') {
      handleError('Invalid Client Name !');
      return;
    }

    if (formData.contactNo === undefined || formData.contactNo.trim() === '') {
      handleError('Invalid Contact Number !');
      return;
    }

    if (formData.city === undefined || formData.city === '') {
      handleError('Invalid Client City !');
      return;
    }

    // get token from local storage
    const storedToken = localStorage.getItem('userToken');

    // check if token then parse into json and use it
    const token = storedToken ? JSON.parse(storedToken) : null;

    if (token) {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };
      fetch('https://quotationserver.aiodock.com/addClient', {
        method: 'POST',
        body: JSON.stringify(formData),
        headers,
      })
        .then((response) => response.json())
        .then((data) => {
          setFormData({
            clientName: '',
            clientAddress: '',
            city: '',
            company: '',
            contactNo: '',
            clientDesignation: '',
          });
          setPhoneNumber('');
          handleSuccess('Client Added Successfully !');
          setTimeout(() => {
            handleClose();
          }, 2000);
          handleClose();
        });
    } else {
      alert('token not found to fullfill this request');
    }
  };

  // Handle Data Change
  const handleChangeData = (event) => {
    const { name, value } = event.target;
    setFormData((formData) => ({
      ...formData,
      [name]: value,
    }));
  };

  // Space after 4 digits in Phone Number
  const handlePhoneNumberChange = (event) => {
    const input = event.target.value;
    const formattedInput = input.replace(/\s/g, '').replace(/(\d{4})(.*)/, '$1 $2');
    setPhoneNumber(formattedInput);
    setFormData((formData) => ({
      ...formData,
      contactNo: formattedInput,
    }));
  };

  return (
    <>
      <Dialog open={open} maxWidth="sm" onClose={handleClose}>
        {/* <Box sx={style}> */}
        <ThemeProvider theme={theme}>
          <DialogTitle id="customized-dialog-title">Add New Client</DialogTitle>
          <Divider fullWidth />
          <DialogContent>
            <Box component="form" id="modal-modal-description" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <TextField
                    fullWidth
                    margin="normal"
                    required
                    label="Name"
                    value={formData.clientName}
                    name="clientName"
                    onChange={handleChangeData}
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    fullWidth
                    margin="normal"
                    // required
                    label="Designation"
                    value={formData.clientDesignation}
                    name="clientDesignation"
                    onChange={handleChangeData}
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    margin="normal"
                    fullWidth
                    // required
                    label="Company"
                    value={formData.company}
                    name="company"
                    onChange={handleChangeData}
                    // autoFocus
                  />
                </Grid>

                <Grid item sm={6}>
                  <TextField
                    margin="normal"
                    fullWidth
                    required
                    label="Contact No #"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    name="contactNo"
                    // autoFocus
                  />
                </Grid>

                <Grid item sm={6}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="City"
                    value={formData.city}
                    name="city"
                    onChange={handleChangeData}
                    // autoFocus
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    margin="normal"
                    fullWidth
                    // required
                    label="Address"
                    value={formData.clientAddress}
                    name="clientAddress"
                    onChange={handleChangeData}
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button type="submit" onClick={handleSubmit} variant="contained">
              Create
            </Button>
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
          </DialogActions>
        </ThemeProvider>
      </Dialog>
    </>
  );
}
