import * as React from 'react';
import { useState, useEffect } from 'react';
// @mui

import {
  Divider,
  DialogTitle,
  Grid,
  Box,
  Stack,
  Button,
  Container,
  Typography,
  TextField,
  Modal,
  Snackbar,
  Alert,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// components

export default function EditSmdPrice(props) {
  const theme = createTheme();
  // ---------------------Modal-------------------------------
  const [openModal, setOpenModal] = useState(true);
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };
  const [openEditModal, setOpenEditModal] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [formData, setFormData] = useState(props.data);
  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedData = {
      ...formData,
    };
    // get token from local storage
    const storedToken = localStorage.getItem('userToken');

    // check if token then parse into json and use it
    const token = storedToken ? JSON.parse(storedToken) : null;

    if (token) {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };
      fetch(`https://quotationserver.aiodock.com/updateUser/${formData._id}`, {
        method: 'PUT',
        body: JSON.stringify(updatedData),
        headers,
      })
        .then((response) => response.json())
        .then((data) => {
          setFormData({
            userName: '',
            email: '',
            userPassword: '',
            role: '',
          });
          setOpenSnackbar(true);
        })
        .catch((error) => {
          console.error('Update failed:', error);
        });
    } else {
      alert('token not found to fullfill this request');
    }
  };

  useEffect(() => {
    if (openSnackbar) {
      const snackbarTimer = setTimeout(() => {
        props.onClose();
      }, 1500);

      return () => {
        clearTimeout(snackbarTimer);
      };
    }
    return undefined;
  }, [openSnackbar]);

  const handleChangeData = (event) => {
    const { name, value } = event.target;
    setFormData((formData) => ({
      ...formData,
      [name]: value,
    }));
  };


  return (
    <>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Modal open={openEditModal} onClose={props.onClose}>
            <Box sx={style}>
              <ThemeProvider theme={theme}>
                <DialogTitle id="customized-dialog-title" sx={{ textAlign: 'center' }}>
                  Update Module
                </DialogTitle>
                <Divider fullWidth />
                {/* <Button onClick={handleCloseModal}></Button> */}

                <Box component="form" id="modal-modal-description" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                  <Grid container>
                    <TextField
                      margin="normal"
                      fullWidth
                      required
                      label="User Name"
                      value={formData.userName}
                      name="userName"
                      onChange={handleChangeData}
                      autoFocus
                    />

                    <TextField
                      margin="normal"
                      fullWidth
                      required
                      label="Email"
                      value={formData.email}
                      name="email"
                      onChange={handleChangeData}
                      autoFocus
                    />
                    <TextField
                      margin="normal"
                      fullWidth
                      required
                      label="Password"
                      value={formData.userPassword}
                      name="userPassword"
                      onChange={handleChangeData}
                      autoFocus
                    />
                    <FormControl fullWidth>
                      <TextField
                        margin="normal"
                        id="outlined-select-currency"
                        select
                        label="Select Role"
                        name="role"
                        value={formData.role}
                        onChange={handleChangeData}
                        autoFocus
                      >
                        <MenuItem value="admin">Admin</MenuItem>
                        <MenuItem value="user">User</MenuItem>
                      </TextField>
                    </FormControl>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Button type="submit" color="secondary" variant="contained" sx={{ mt: 3, mb: 2, width: '50%' }}>
                        Update
                      </Button>
                      <Button onClick={props.onClose} variant="outlined" sx={{ mt: 3, mb: 2, mx: 3, width: '50%' }}>
                        Cancel
                      </Button>
                    </Box>
                  </Grid>
                </Box>
              </ThemeProvider>

              <Snackbar
                open={openSnackbar}
                autoHideDuration={1500}
                onClose={() => setOpenSnackbar(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <Alert variant="filled" severity="success" sx={{ width: '100%' }}>
                  Updated Succesfully !
                </Alert>
              </Snackbar>
            </Box>
          </Modal>
        </Stack>
      </Container>
    </>
  );
}
