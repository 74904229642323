import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, IconButton, InputAdornment, TextField, Box, Snackbar, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../components/iconify';

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [email, setEmail]= useState("")
  const [password , setPassword]= useState("")


  // const handleLogIn = async (event) => {
  //   event.preventDefault();
  //   const data = new FormData(event.currentTarget);
  //   const credentials = {
  //     userName: data.get('email'),
  //     userPassword: data.get('password'),
  //   };

  //   try {
  //     const response = await fetch('https://quotationserver.aiodock.com/userLogin', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(credentials),
  //     });

  //     const responseData = await response.json();
  //     const token = responseData.token;
  //     localStorage.setItem('userToken', JSON.stringify(token));
  //     if (response.ok) {
  //       localStorage.setItem('user', 'true');
  //       setOpenSuccess(true);
  //       setTimeout(() => {
  //         navigate('/dashboard/app', { replace: true });
  //       }, 1000);
  //     } else {
  //       setOpenError(true);
  //       localStorage.clear();
  //     }
  //   } catch (error) {
  //     console.error('An error occurred:', error);
  //     setOpenError(true);
  //     localStorage.clear();
  //   }
  // };

  const handleLogIn = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const credentials = {
      usernNme: data.get('email'),
      userPassword: data.get('password'),
    };

    try {
      const response = await fetch('https://quotationserver.aiodock.com/userLogin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userName:email,
          userPassword:password
        }),
      });

      if (response.ok) {
        const responseData = await response.json();
        // console.log(responseData,"ssssssss")
        const token = responseData.token;
        const role = responseData.role;
        const name = responseData.name;
        const email = responseData.email;
        localStorage.setItem('userToken', JSON.stringify(token));
        localStorage.setItem('role', role);
        localStorage.setItem('name', name);
        localStorage.setItem('email', email);
        setOpenSuccess(true);
        setTimeout(() => {
          navigate('/dashboard/app', { replace: true });
        }, 1000);
      } else {
        setOpenError(true);
        localStorage.clear();
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setOpenError(true);
      localStorage.clear();
    }
  };

  return (
    <Box component="form" onSubmit={handleLogIn} noValidate sx={{ mt: 1 }}>
      <Stack spacing={3}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          onChange={(e)=>setEmail(e.target.value)}
        />

        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          id="password"
          type={showPassword ? 'text' : 'password'}
          onChange={(e)=>setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

      <LoadingButton fullWidth size="large" type="submit" variant="contained">
        Login
      </LoadingButton>

      {/* Snackbar for success */}
      <Snackbar
        open={openSuccess}
        autoHideDuration={1000}
        onClose={() => setOpenSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert variant="filled" severity="success" sx={{ width: '100%' }}>
          LogIn Successfully !
        </Alert>
      </Snackbar>

      {/* Snackbar for error */}
      <Snackbar
        open={openError}
        autoHideDuration={1000}
        onClose={() => setOpenError(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert variant="filled" severity="error" sx={{ width: '100%' }}>
          Invalid Credentials !
        </Alert>
      </Snackbar>
    </Box>
  );
}
