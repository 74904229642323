import * as React from 'react';
import { useState, useEffect } from 'react';
// date and time

import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// @mui
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import {
  Box,
  Stack,
  Button,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Container,
  Typography,
  TextField,
  Modal,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  CssBaseline,
  Autocomplete,
  Snackbar,
  Alert,
  Divider,
  DialogTitle,
  Dialog,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// components

export default function EditQoutation(props) {
  // date n timne
  const [value, setValue] = React.useState(dayjs('2014-08-18T21:11:54'));
  const [leaveFrom, setLeaveFrom] = React.useState(dayjs('2014-08-18T21:11:54'));
  const [leaveTo, setLeaveTo] = React.useState(dayjs('2014-08-18T21:11:54'));
  const [formData, setFormData] = useState(props.data);
  const [date, setDate] = React.useState(dayjs(formData.date));
  const [newModule, setNewModule] = useState(formData.module);
  const [newCategory, setNewCategory] = useState(formData.category);
  const [newBrand, setNewBrand] = useState(formData.brand);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [moduleSnackbar, setModuleSnackbar] = useState(false);

  // const [rate, setRate] = React.useState(0);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  // ---------------------Modal-------------------------------
  const [openModal, setOpenModal] = useState(true);
  //   const [open, setOpen] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    props.handleCloseEditModal();
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1150,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: '95%',
  };
  const [openEditModal, setOpenEditModal] = useState(true);
  const theme = createTheme();
  const navigate = useNavigate();
  //   ----- Image -----
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  const handleSubmit = (e) => {
    e.preventDefault();
    formData.date = date.toISOString();
    const updatedData = {
      ...formData,
    };
    // get token from local storage
    const storedToken = localStorage.getItem('userToken');

    // check if token then parse into json and use it
    const token = storedToken ? JSON.parse(storedToken) : null;

    if (token) {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };
      fetch(`https://quotationserver.aiodock.com/updateQuotation/${formData._id}`, {
        method: 'PUT',
        headers,
        body: JSON.stringify(updatedData),
      })
        .then((response) => {
          if (response.status === 401) {
            const confirmLogout = window.confirm('Your session has expired. Do you want to log out?');
            if (confirmLogout) {
              localStorage.removeItem('userToken');
              navigate('/login');
            }
          }
          return response.json();
        })
        .then((data) => {
          setFormData({
            surName: '',
            screenLabour: 0,
            videoProcessor: '',
            videoProcessorDescription: '',
            cardInfo: '',
            PON: '',
            rate: 0,
            clientId: '',
            screenWidth: 0,
            screenHeight: 0,
            noOfScreen: '',
            sendingCardInfo: '',
            receivingCardInfo: '',
            cabinet: '',
            cabinetInfo: '',
            gst: '',
            gstPercentage: 0,
            termsAndConditions: '',
            module: '',
            category: '',
            discount: '',
            signByName: '',
            signByDesignation: '',
            signByCompanyName: '',
            payment: '',
            validity: '',
            warranty: '',
            status: '',
            remarks: '',
            frame: '',
            modulesInWidth: '',
            modulesInHeight: '',
          });
          setOpenSnackbar(true);
        })
        .catch((error) => {
          console.error('Update failed:', error);
          // handle error response
        });
    } else {
      alert('token not found to fullfill this request');
    }
  };

  useEffect(() => {
    if (openSnackbar) {
      const snackbarTimer = setTimeout(() => {
        props.onClose();
      }, 1500);

      return () => {
        clearTimeout(snackbarTimer);
      };
    }
    return undefined;
  }, [openSnackbar]);

  const handleUpdateClientName = (newValue) => {
    const updatedFormData = { ...formData };
    const selectedClient = clients.find((client) => client.clientName === newValue);
    updatedFormData.clientName = newValue;
    updatedFormData.clientId = selectedClient ? selectedClient.clientId : '';
    updatedFormData.clientAddress = selectedClient ? selectedClient.clientAddress : '';
    updatedFormData.clientCompany = selectedClient ? selectedClient.company : '';
    updatedFormData.contactNo = selectedClient ? selectedClient.contactNo : '';
    updatedFormData.clientCity = selectedClient ? selectedClient.city : '';
    setFormData(updatedFormData);
  };

  const handleUpdateModuleName = (newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      module: newValue,
    }));
    setNewModule(newValue);
  };

  const handleUpdateCategoryName = (newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      category: newValue,
    }));
    setNewCategory(newValue);
  };

  const handleUpdateBrandName = (newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      brand: newValue,
    }));
    setNewBrand(newValue);
  };

  const handleChangeData = (event) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // -->> For Iron Structure
  const handleModulesWidthChange = (event) => {
    const numberOfModulesInWidth = event.target.value;
    const selectedCategory = document.getElementById('categorySelect').value;

    // With Frame
    // Convert to Inches (width)
    let widthWithFrameInches;

    if (selectedCategory === 'OUTDOOR') {
      widthWithFrameInches = (numberOfModulesInWidth * (320 / 25.4) + 3).toFixed(2);
    } else {
      // Convert to Inches with indoor frame
      widthWithFrameInches = (numberOfModulesInWidth * (320 / 25.4) + 1.5).toFixed(2);
    }

    // Convert to Feet(width)
    const widthWithFrameFeet = (widthWithFrameInches / 12).toFixed(2);
    document.getElementById('smd-width').value = widthWithFrameFeet;

    setFormData((formData) => ({
      ...formData,
      screenWidth: widthWithFrameFeet,
      modulesInWidth: numberOfModulesInWidth,
    }));
  };

  const handleModulesHeightChange = (event) => {
    const numberOfModulesInHeight = event.target.value;
    const selectedCategory = document.getElementById('categorySelect').value;

    let heightWithFrameInches;

    if (selectedCategory === 'OUTDOOR') {
      // Convert to Inches with outdoor frame
      heightWithFrameInches = (numberOfModulesInHeight * (160 / 25.4) + 3).toFixed(2);
    } else {
      // Convert to Inches with indoor frame
      heightWithFrameInches = (numberOfModulesInHeight * (160 / 25.4) + 1.5).toFixed(2);
    }

    // Convert to Feet (height)
    const heightWithFrameFeet = (heightWithFrameInches / 12).toFixed(2);

    document.getElementById('smd-height').value = heightWithFrameFeet;
    setFormData((formData) => ({
      ...formData,
      screenHeight: heightWithFrameFeet,
      modulesInHeight: numberOfModulesInHeight,
    }));
  };

  // -->> For Cabinet Structure
  const handleCabinetWidthChange = (event) => {
    const numberOfCabinetsInWidth = event.target.value;
    const selectedCategory = document.getElementById('categorySelect').value;

    // With Frame
    // Convert to Inches (width)
    let widthWithFrameInches;

    if (selectedCategory === 'OUTDOOR') {
      widthWithFrameInches = numberOfCabinetsInWidth * (960 / 25.4).toFixed(2);
    } else {
      // Convert to Inches with indoor frame
      widthWithFrameInches = numberOfCabinetsInWidth * (640 / 25.4).toFixed(2);
    }

    // Convert to Feet(width)
    const widthWithFrameFeet = (widthWithFrameInches / 12).toFixed(2);
    document.getElementById('cabinet-width').value = widthWithFrameFeet;

    setFormData((formData) => ({
      ...formData,
      screenWidth: widthWithFrameFeet,
      modulesInWidth: numberOfCabinetsInWidth,
    }));
  };

  const handleCabinetHeightChange = (event) => {
    const numberOfCabinetsInHeight = event.target.value;
    const selectedCategory = document.getElementById('categorySelect').value;

    let heightWithFrameInches;

    if (selectedCategory === 'OUTDOOR') {
      // Convert to Inches with outdoor frame
      heightWithFrameInches = numberOfCabinetsInHeight * (960 / 25.4).toFixed(2);
    } else {
      // Convert to Inches with indoor frame
      heightWithFrameInches = numberOfCabinetsInHeight * (480 / 25.4).toFixed(2);
    }

    // Convert to Feet (height)
    const heightWithFrameFeet = (heightWithFrameInches / 12).toFixed(2);

    document.getElementById('cabinet-height').value = heightWithFrameFeet;
    setFormData((formData) => ({
      ...formData,
      screenHeight: heightWithFrameFeet,
      modulesInHeight: numberOfCabinetsInHeight,
    }));
  };

  const [clients, setClients] = useState([]);
  useEffect(() => {
    // get token from local storage
    const storedToken = localStorage.getItem('userToken');

    // check if token then parse into json and use it
    const token = storedToken ? JSON.parse(storedToken) : null;

    if (token) {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };
      fetch('https://quotationserver.aiodock.com/showClients', {
        method: 'GET',
        headers,
      }).then((result) => {
        result.json().then((response) => {
          setClients(response.message);
        });
      });
    } else {
      alert('token not found to fullfill this request');
    }
  }, []);

  const [smdModule, setSmdModule] = useState([]);
  useEffect(() => {
    // get token from local storage
    const storedToken = localStorage.getItem('userToken');

    // check if token then parse into json and use it
    const token = storedToken ? JSON.parse(storedToken) : null;

    if (token) {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };
      fetch('https://quotationserver.aiodock.com/showModule', {
        method: 'GET',
        headers,
      }).then((result) => {
        result.json().then((response) => {
          setSmdModule(response.message);
        });
      });
    } else {
      alert('token not found to fullfill this request');
    }
  }, []);

  const uniqueModules = [...new Set(smdModule.map((module) => module.moduleName))];
  const uniqueCategories = [...new Set(smdModule.map((category) => category.moduleCategory))];
  const uniqueBrand = [...new Set(smdModule.map((brand) => brand.moduleBrand))];

  const handlePriceButtonClick = () => {
    if (newModule && newCategory && newBrand) {
      // get token from local storage
      const storedToken = localStorage.getItem('userToken');

      // check if token then parse into json and use it
      const token = storedToken ? JSON.parse(storedToken) : null;

      if (token) {
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        };
        fetch('https://quotationserver.aiodock.com/showModule', {
          method: 'GET',
          headers,
        })
          .then((result) => result.json())
          .then((response) => {
            const module = response.message.find(
              (item) =>
                item.moduleName === newModule && item.moduleCategory === newCategory && item.moduleBrand === newBrand
            );

            if (module) {
              const price = module.moduleRate;
              setFormData((formData) => ({
                ...formData,
                rate: price,
              }));
            } else {
              setModuleSnackbar(true);
            }
          })
          .catch((error) => {
            console.error('Error fetching module data:', error);
          });
      } else {
        alert('token not found to fullfill this request');
      }
    } else {
      console.error('Invalid module and category selected.');
    }
  };
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };
  return (
    <>
      <Dialog fullScreen open={openModal} onClose={props.onClose}>
        <Box component="form" onSubmit={handleSubmit} noValidate style={{ width: '100%' }}>
          <div style={{ position: 'sticky', top: 0 }}>
            <AppBar sx={{ position: 'relative' }}>
              <Toolbar>
                <IconButton edge="start" color="inherit" onClick={props.onClose} aria-label="close">
                  <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                  Edit Quotation
                </Typography>
                <Button type="submit" variant="outlined" color="inherit" sx={{ borderColor: '#fff' }}>
                  Update
                </Button>
              </Toolbar>
            </AppBar>
          </div>
          <Container>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                {/* <Modal open={openModal} onClose={props.onClose}> */}
                {/* <Box sx={style}> */}
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  {/* <DialogTitle id="customized-dialog-title" sx={{ textAlign: 'center' }}>
                      UPDATE QUOTATION -<span style={{ color: 'red' }}>{formData.clientName}</span>-
                    </DialogTitle> */}
                  <Divider fullWidth />

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={2}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          inputFormat="dd/MM/yyyy"
                          value={date}
                          label={'Date'}
                          onChange={(date) => {
                            setDate(date);
                          }}
                          renderInput={(params) => <TextField margin="normal" fullWidth {...params} />}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                      <FormControl fullWidth>
                        <TextField
                          variant="filled"
                          margin="normal"
                          select
                          label="Title"
                          name="surName"
                          value={formData.surName}
                          onChange={handleChangeData}
                          autoFocus
                        >
                          <MenuItem value="Mr">Mr</MenuItem>
                          <MenuItem value="Ms">Ms</MenuItem>
                        </TextField>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4} sm={6}>
                      <Autocomplete
                        options={clients.map((client) => client.clientName)}
                        value={formData.clientName}
                        name="clientName"
                        onChange={(event, newValue) => {
                          handleUpdateClientName(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            margin="normal"
                            required
                            fullWidth
                            id="name"
                            label="Client Business Name"
                            name="name"
                            autoComplete="name"
                            autoFocus
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="noOfScreen"
                        value={formData.noOfScreen}
                        onChange={handleChangeData}
                        label="No. of Screens"
                        type="number"
                        id="screen"
                        autoComplete="current-address"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                      <FormControl fullWidth>
                        <TextField
                          variant="filled"
                          margin="normal"
                          select
                          label="Frame Structure"
                          name="frame"
                          value={formData.frame}
                          onChange={handleChangeData}
                          autoFocus
                        >
                          <MenuItem value="" disabled>
                            Select Frame
                          </MenuItem>
                          <MenuItem value="Iron">Iron</MenuItem>
                          <MenuItem value="Cabinet">Cabinet</MenuItem>
                        </TextField>
                      </FormControl>
                    </Grid>
                    {formData.frame === 'Iron' && (
                      <>
                        <Grid item xs={12} sm={6} md={2}>
                          <Autocomplete
                            id="categorySelect"
                            options={uniqueCategories}
                            getOptionLabel={(category) => category}
                            onChange={(event, newValue) => {
                              handleUpdateCategoryName(newValue);
                            }}
                            value={formData.category}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Category"
                                margin="normal"
                                required
                                type="text"
                                variant="filled"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                          <TextField
                            margin="normal"
                            required
                            name="width"
                            label="Modules in Width"
                            type="text"
                            value={formData.modulesInWidth}
                            onChange={handleModulesWidthChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                          <TextField
                            margin="normal"
                            required
                            name="height"
                            label="Modules in Height"
                            type="text"
                            value={formData.modulesInHeight}
                            onChange={handleModulesHeightChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="screenWidth"
                            value={formData.screenWidth}
                            // onChange={handleChangeData}
                            label="Width (Feets)"
                            type="number"
                            id="smd-width"
                            defaultValue="0"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            defaultValue="0"
                            name="screenHeight"
                            value={formData.screenHeight}
                            // onChange={handleChangeData}
                            label="Height (Feets)"
                            type="number"
                            id="smd-height"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2} />
                      </>
                    )}
                    {formData.frame === 'Cabinet' && (
                      <>
                        <Grid item xs={12} sm={6} md={2}>
                          <Autocomplete
                            id="categorySelect"
                            options={uniqueCategories}
                            getOptionLabel={(category) => category}
                            onChange={(event, newValue) => {
                              handleUpdateCategoryName(newValue);
                            }}
                            value={formData.category}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Category"
                                margin="normal"
                                required
                                type="text"
                                variant="filled"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                          <TextField
                            margin="normal"
                            required
                            name="width"
                            label="Cabinets in Width"
                            type="text"
                            value={formData.modulesInWidth}
                            onChange={handleCabinetWidthChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                          <TextField
                            margin="normal"
                            required
                            name="height"
                            label="Cabinets in Height"
                            type="text"
                            value={formData.modulesInHeight}
                            // value={formData.modulesInHeight}
                            onChange={handleCabinetHeightChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            // name="screenWidth"
                            // value={formData.screenWidth}
                            // onChange={handleChangeData}
                            label="Width (Feets)"
                            type="number"
                            id="cabinet-width"
                            defaultValue="0"
                            value={formData.screenWidth}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            // defaultValue="0"
                            name="screenHeight"
                            // value={formData.screenHeight}
                            // onChange={handleChangeData}
                            label="Height (Feets)"
                            type="number"
                            id="cabinet-height"
                            value={formData.screenHeight}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2} />
                      </>
                    )}
                    <Grid item xs={12} sm={6} md={2}>
                      <Autocomplete
                        id="moduleSelect"
                        options={uniqueModules}
                        getOptionLabel={(module) => module}
                        value={formData.module}
                        name="module"
                        onChange={(event, newValue) => {
                          handleUpdateModuleName(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            margin="normal"
                            required
                            fullWidth
                            id="module"
                            label="Module"
                            name="module"
                            autoComplete="module"
                            autoFocus
                            variant="filled"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                      <Autocomplete
                        id="brandSelect"
                        options={uniqueBrand}
                        getOptionLabel={(brand) => brand}
                        value={formData.brand}
                        name="brand"
                        onChange={(event, newValue) => {
                          handleUpdateBrandName(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            margin="normal"
                            required
                            fullWidth
                            id="brand"
                            label="Brand"
                            name="brand"
                            autoComplete="brand"
                            autoFocus
                            variant="filled"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                      <Button
                        fullWidth
                        color="success"
                        size="sm"
                        variant="contained"
                        style={{ marginTop: 23 }}
                        onClick={handlePriceButtonClick}
                      >
                        Add Price
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        value={formData.rate}
                        onChange={handleChangeData}
                        name="rate"
                        label={`Rate Per ft² (${numberWithCommas(formData.rate)})`}
                        type="number"
                        id="rate"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="discount"
                        value={formData.discount}
                        onChange={handleChangeData}
                        label="Discount Per ft²"
                        type="number"
                        id="discount"
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={2}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="screenLabour"
                        value={formData.screenLabour}
                        onChange={handleChangeData}
                        label="Screen Labour"
                        type="number"
                      />
                    </Grid> */}
                    <Grid item xs={12} sm={6} md={2}>
                      <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">GST</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="gst"
                          value={formData.gst}
                          onChange={handleChangeData}
                        >
                          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                          <FormControlLabel value="no" control={<Radio />} label="No" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="sendingCardInfo"
                        onChange={handleChangeData}
                        label="Sending Card Information"
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        value={formData.receivingCardInfo}
                        onChange={handleChangeData}
                        name="receivingCardInfo"
                        label="Receiving Card Information"
                        type="text"
                      />
                    </Grid> */}
                    <Grid item xs={12} sm={6} md={2}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="PON"
                        value={formData.PON}
                        onChange={handleChangeData}
                        label="PO No."
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                      <FormControl fullWidth>
                        <TextField
                          variant="filled"
                          margin="normal"
                          select
                          label="Status"
                          name="status"
                          value={formData.status}
                          onChange={handleChangeData}
                          autoFocus
                        >
                          <MenuItem value="" disabled>
                            Select Status
                          </MenuItem>
                          <MenuItem value="Sent / WIP">Sent / WIP</MenuItem>
                          <MenuItem value="Win">Win</MenuItem>
                          <MenuItem value="Loss">Loss</MenuItem>
                          <MenuItem value="Contact Later">Contact Later</MenuItem>
                        </TextField>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="cardInfo"
                        onChange={handleChangeData}
                        value={formData.cardInfo}
                        label="Card Information"
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="videoProcessor"
                        onChange={handleChangeData}
                        value={formData.videoProcessor}
                        label="Video Processor"
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="ntn"
                        onChange={handleChangeData}
                        value={formData.ntn}
                        label="NTN No."
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="stn"
                        onChange={handleChangeData}
                        value={formData.stn}
                        label="STN No."
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="videoProcessorDescription"
                        onChange={handleChangeData}
                        value={formData.videoProcessorDescription}
                        label="Video Processor Description"
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      {' '}
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="remarks"
                        value={formData.remarks}
                        onChange={handleChangeData}
                        label="Remarks"
                        type="text"
                      />
                    </Grid>

                    {/* <Grid item={2}>
                        <TextField
                          margin="normal"
                          fullWidth
                          style={{ width: '23em', marginRight: 6 }}
                          name="gstPercentage"
                          value={formData.gstPercentage}
                          onChange={handleChangeData}
                          label="GST Percentage"
                          type="number"

                        />
                      </Grid> */}
                    {/* <Grid item={2}>
                      <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">Cabinet</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="cabinet"
                          onChange={handleChangeData}
                          value={formData.cabinet}
                        >
                          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                          <FormControlLabel value="no" control={<Radio />} label="No" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>

                    <Grid item={2}>
                      <TextField
                        margin="normal"
                        fullWidth
                        style={{ width: '24em', marginRight: 6 }}
                        name="cabinetInfo"
                        value={formData.cabinetInfo}
                        onChange={handleChangeData}
                        label="Cabinet Information"
                        type="text"
                      />
                    </Grid> */}
                    {/* 
                      <Grid item={2}>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          style={{ width: '22em', paddingRight: 7 }}
                          name="signByName"
                          value={formData.signByName}
                          onChange={handleChangeData}
                          label="Sign By Name"
                          type="text"
                        />
                      </Grid>
                      <Grid item={2}>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          style={{ width: '22em', paddingRight: 7 }}
                          name="signByDesignation"
                          value={formData.signByDesignation}
                          onChange={handleChangeData}
                          label="Sign By Designation"
                          type="text"
                        />
                      </Grid>
                      <Grid item={2}>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          style={{ width: '21em' }}
                          name="signByCompanyName"
                          onChange={handleChangeData}
                          value={formData.signByCompanyName}
                          label="Sign By Comapany Name"
                          type="text"
                        />
                      </Grid> */}
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="payment"
                        value={formData.payment}
                        onChange={handleChangeData}
                        label="Payment"
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="validity"
                        value={formData.validity}
                        onChange={handleChangeData}
                        label="Delivery Time"
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="warranty"
                        value={formData.warranty}
                        onChange={handleChangeData}
                        label="Service Warranty"
                        type="text"
                      />
                    </Grid>
                  </Grid>

                  {/* <Box sx={{ display: 'flex' }}>
                        <Button type="submit" color="secondary" variant="contained" sx={{ mb: 2, width: '10%' }}>
                          Update
                        </Button>
                        <Button onClick={props.onClose} variant="outlined" sx={{ mb: 2, mx: 3, width: '10%' }}>
                          Cancel
                        </Button>
                      </Box> */}
                  {/* </Box> */}
                </ThemeProvider>
                <Snackbar
                  open={openSnackbar}
                  autoHideDuration={3000} // set the Snackbar to auto-hide after 3 seconds
                  onClose={() => setOpenSnackbar(false)} // set the function to close the Snackbar when it is clicked
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                  <Alert variant="filled" severity="success" sx={{ width: '100%' }}>
                    Quotation Updated Succesfully!
                  </Alert>
                </Snackbar>
                <Snackbar
                  open={moduleSnackbar}
                  autoHideDuration={1500}
                  onClose={() => setModuleSnackbar(false)}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                  <Alert variant="filled" severity="error" sx={{ width: '100%' }}>
                    Module Not Exists. <br />
                    Please Change its Category or Brand.
                  </Alert>
                </Snackbar>
                {/* </Modal>  */}
              </Stack>
            </LocalizationProvider>
          </Container>
        </Box>
      </Dialog>
    </>
  );
}
