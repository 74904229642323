import * as React from 'react';
import { useState, useEffect } from 'react';
import {
  IconButton,
  Divider,
  Box,
  Stack,
  Button,
  Container,
  Typography,
  DialogTitle,
  TextField,
  Modal,
  Snackbar,
  Alert,
  FormControl,
  Grid,
  MenuItem,
  InputLabel,
  Select,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { Cancel } from '@mui/icons-material';

export default function SmdPrice(props) {
  const theme = createTheme();

  // ---------------------Modal-------------------------------
  const [openModal, setOpenModal] = useState(true);
  const handleOpenModal = () => setOpenModal(true);

  // const handleCloseModal = () => {props.onClose; // Call the props handleCloseModal function
  // };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  // SnackBar for handling success
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [result, setResult] = useState(null);
  const [formData, setFormData] = useState({
    moduleName: '',
    moduleCategory: '',
    moduleBrand: '',
    moduleRate: 0,
  });

  // Form validity status
  const [isFormValid, setFormValid] = useState(true);

  // Submit Data to the Database
  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if the form is valid
    if (!e.target.checkValidity()) {
      setFormValid(false);
      return; // Don't submit if the form is invalid
    }

    setFormValid(true);
    // get token from local storage
    const storedToken = localStorage.getItem('userToken');

    // check if token then parse into json and use it
    const token = storedToken ? JSON.parse(storedToken) : null;
  
    if (token) {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };
      fetch('https://quotationserver.aiodock.com/addModule', {
        method: 'POST',
        body: JSON.stringify(formData),
        headers,
      })
        .then((response) => response.json())
        .then((data) => {
          setResult(data);
          setFormData({
            moduleName: '',
            moduleCategory: '',
            moduleBrand: '',
            moduleRate: '',
          });
          setOpenSnackbar(true);
        })
        .catch(() => {
          setResult({
            success: false,
            message: 'Something went wrong. Try again later',
          });
        });
    } else {
      alert('token not found to fullfill this request');
    }
  };

  useEffect(() => {
    if (openSnackbar) {
      const snackbarTimer = setTimeout(() => {
        props.onClose();
      }, 1500);

      return () => {
        clearTimeout(snackbarTimer);
      };
    }
    return undefined;
  }, [openSnackbar]);

  // Handle Data Change
  const handleChangeData = (event) => {
    const { name, value } = event.target;
    setFormData((formData) => ({
      ...formData,
      [name]: value,
    }));
  };

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };
  return (
    <>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Modal open={openModal} onClose={props.onClose}>
            <Box sx={style}>
              <ThemeProvider theme={theme}>
                <DialogTitle id="customized-dialog-title" sx={{ textAlign: 'center' }}>
                  Add New Module
                </DialogTitle>
                <Divider fullWidth />
                {!isFormValid && (
                  <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                    Please fill in all required fields.
                  </Typography>
                )}
                <Box
                  component="form"
                  id="modal-modal-description"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{ mt: 1 }}
                  dividers
                >
                  <Grid container>
                    <TextField
                      margin="normal"
                      fullWidth
                      required
                      label="Module Name"
                      value={formData.moduleName}
                      name="moduleName"
                      onChange={handleChangeData}
                      autoFocus
                    />
                    <FormControl fullWidth>
                      <TextField
                        margin="normal"
                        id="outlined-select-currency"
                        select
                        label="Select Category"
                        name="moduleCategory"
                        value={formData.moduleCategory}
                        onChange={handleChangeData}
                        autoFocus
                      >
                        <MenuItem value="INDOOR">INDOOR</MenuItem>
                        <MenuItem value="OUTDOOR">OUTDOOR</MenuItem>
                      </TextField>
                    </FormControl>
                    {/* <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Module Category</InputLabel>
                      <Select
                        required
                        labelId="demo-simple-select-label"
                      
                      >
                        <MenuItem value="INDOOR">INDOOR</MenuItem>
                        <MenuItem value="OUTDOOR">OUTDOOR</MenuItem>
                      </Select>
                    </FormControl> */}
                    <TextField
                      margin="normal"
                      fullWidth
                      required
                      label="Module Brand"
                      value={formData.moduleBrand}
                      name="moduleBrand"
                      onChange={handleChangeData}
                      autoFocus
                    />
                    <TextField
                      type="number"
                      margin="normal"
                      fullWidth
                      required
                      label={`Rate Per Sq. Feet (${numberWithCommas(formData.moduleRate)})`}
                      value={formData.moduleRate}
                      name="moduleRate"
                      onChange={handleChangeData}
                      autoFocus
                    />

                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2, width: '50%' }}>
                        Save
                      </Button>
                      <Button onClick={props.onClose} variant="outlined" sx={{ mt: 3, mb: 2, mx: 3, width: '50%' }}>
                        Cancel
                      </Button>
                    </Box>
                  </Grid>
                </Box>
              </ThemeProvider>
              <Snackbar
                open={openSnackbar}
                autoHideDuration={1500}
                onClose={() => setOpenSnackbar(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <Alert variant="filled" severity="success" sx={{ width: '100%' }}>
                  New Module Created Successfully!
                </Alert>
              </Snackbar>
            </Box>
          </Modal>
        </Stack>
      </Container>
    </>
  );
}
