import * as React from 'react';
import { useState, useEffect } from 'react';

// @mui
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider, Box, Button, TextField, Grid } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// components
export default function EditLeaveForm({ handleSuccess, handleError, handleClose, open, data }) {
  const theme = createTheme();
  const [formData, setFormData] = useState(data);

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedData = {
      ...formData,
    };

    if (formData.clientName === undefined || formData.clientName.trim() === '') {
      handleError('Invalid Client Name !');
      return;
    }

    if (formData.contactNo === undefined || formData.contactNo.trim() === '') {
      handleError('Invalid Contact Number !');
      return;
    }

    if (formData.city === undefined || formData.city === '') {
      handleError('Invalid Client City !');
      return;
    }

    // get token from local storage
    const storedToken = localStorage.getItem('userToken');

    // check if token then parse into json and use it
    const token = storedToken ? JSON.parse(storedToken) : null;

    if (token) {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };
      fetch(`https://quotationserver.aiodock.com/updateClient/${formData._id}`, {
        method: 'PUT',
        body: JSON.stringify(updatedData),
        headers,
      })
        .then((response) => response.json())
        .then((data) => {
          setFormData({
            clientName: '',
            clientAddress: '',
            city: '',
            company: '',
            clientDesignation: '',
            contactNo: '',
          });
          // setOpenSnackbar(true);
          handleSuccess('Client Added Successfully !');
          setTimeout(() => {
            handleClose();
          }, 2000);
          handleClose();
        })
        .catch((error) => {
          console.error('Update failed:', error);
          // handle error response
        });
    } else {
      alert('token not found to fullfill this request or expired');
    }
  };

  const handleChangeData = (event) => {
    const { name, value } = event.target;
    setFormData((formData) => ({
      ...formData,
      [name]: value,
    }));
  };
  return (
    <>
      <Dialog open={open} maxWidth="sm" onClose={handleClose}>
        <ThemeProvider theme={theme}>
          <DialogTitle id="customized-dialog-title" >
            Update Client -{formData.clientName}-
          </DialogTitle>
          <Divider fullWidth />
          <DialogContent>
            <Box component="form" id="modal-modal-description" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <TextField
                    fullWidth
                    margin="normal"
                    required
                    label="Name"
                    value={formData.clientName}
                    name="clientName"
                    onChange={handleChangeData}
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    fullWidth
                    margin="normal"
                    // required
                    label="Designation"
                    value={formData.clientDesignation}
                    name="clientDesignation"
                    onChange={handleChangeData}
                   
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    margin="normal"
                    // required
                    fullWidth
                    id="phone"
                    value={formData.company}
                    label="Company"
                    name="company"
                    onChange={handleChangeData}
                    autoComplete="company"
                    // autoFocus
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    margin="normal"
                    fullWidth
                    required
                    label="Contact No #"
                    value={formData.contactNo}
                    name="contactNo"
                    onChange={handleChangeData}
                    // autoFocus
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="phone"
                    value={formData.city}
                    label="City"
                    name="city"
                    onChange={handleChangeData}
                    autoComplete="category"
                    // autoFocus
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    margin="normal"
                    // required
                    fullWidth
                    id="email"
                    value={formData.clientAddress}
                    label="Address"
                    name="clientAddress"
                    onChange={handleChangeData}
                    // autoFocus
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button type="submit" onClick={handleSubmit} variant="contained">
              Update
            </Button>
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
          </DialogActions>
        </ThemeProvider>
      </Dialog>
    </>
  );
}
