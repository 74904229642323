import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useState, useEffect, forwardRef, useRef } from 'react';

import { useNavigate } from 'react-router-dom';
// Confirm Delete Import
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';

// Material Table Icons Imports
import AddBox from '@mui/icons-material/AddBox';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';
import { CircularProgress } from '@material-ui/core';

// Date and Time
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// Material UI Imorts
import MaterialTable from 'material-table';
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  IconButton,
  TableContainer,
  Snackbar,
  Alert,
  TextField,
} from '@mui/material';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';

// Main Imports
import Qoutation from '../layouts/Quotation/CreateQoutation';
import PrintPDFQoutation from '../layouts/Quotation/PrintQoutation';
import EditQoutation from '../layouts/Quotation/EditQoutation';
import Logs from '../layouts/Quotation/Logs';

// Comma Function
import muiComma from '../components/commaFunction';

// Material Table Icons Defined
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

// const tableData = [
//   { name: 'John Doe', branch: 'ABC Branch', designation: 'Manager', department: 'Sales' },
//   { name: 'Jane Smith', branch: 'XYZ Branch', designation: 'Supervisor', department: 'Marketing' },
//   // Add more employee objects as needed
// ];

export default function Users() {
  // Date and Time

  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [invoiceSnackbar, setInvoiceSnackbar] = useState(false);
  const [invoiceErrorSnackbar, setInvoiceErrorSnackbar] = useState(false);
  const [searchFrom, setSearchFrom] = useState(dayjs().subtract(7, 'days').startOf('day'));
  const [searchTo, setSearchTo] = useState(dayjs().endOf('day'));

  const [quotations, setQuotations] = useState([]);
  const [editData, setEditData] = useState(null);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  // ---------------------Modal-------------------------------
  const [openModal, setOpenModal] = useState(null);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    fetchData();
    handleSearch();
  };

  // --------------------- Edit Modal--------------------------
  const [openEditModal, setOpenEditModal] = useState(false);
  const handleOpenEditModal = () => setOpenEditModal(true);
  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    fetchData();
    handleSearch();
  };

  // --------------------- Logs Modal--------------------------
  const [openLogsModal, setOpenLogsModal] = useState(false);
  const handleOpenLogsModal = () => setOpenLogsModal(true);
  const handleCloseLogsModal = () => {
    setOpenLogsModal(false);
  };

  const handleLogout = () => {
    setQuotations([]);
    const confirmLogout = window.confirm('Your session has expired. Do you want to log out?');
    if (confirmLogout) {
      localStorage.removeItem('userToken');
      navigate('/login');
    }
  };

  // --------------------- PDF Modal--------------------------
  const [openPDFModal, setOpenPDFModal] = useState(false);
  const handleOpenPDFModal = () => setOpenPDFModal(true);
  const handleClosePDFModal = () => setOpenPDFModal(false);

  const handleDelete = (_id) => {
    // show the confirmation alert before deleting the row data
    // get token from local storage
    const storedToken = localStorage.getItem('userToken');

    // check if token then parse into json and use it
    const token = storedToken ? JSON.parse(storedToken) : null;

    if (token) {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };

      confirmAlert({
        title: 'Delete',
        message: 'Are you sure you want to delete this Quotation ?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              fetch(`https://quotationserver.aiodock.com/deleteQuotation/${_id}`, {
                method: 'DELETE',
                headers,
              })
                .then((response) => {
                  if (!response.ok) {
                    if (response.status === 401) {
                      // handleLogout();
                    } else {
                      throw new Error('Network response was not ok');
                    }
                  }
                  setQuotations((prevUsers) => prevUsers.filter((user) => user._id !== _id));
                  setOpenSnackbar(true);
                  fetchData();
                })
                .catch((error) => {
                  console.error('There was an error deleting the employee:', error);
                  if (error && error.response && error.response.status === 401) {
                    // handleLogout();
                  }
                });
            },
          },
          {
            label: 'No',
            onClick: () => {},
          },
        ],
      });
    } else {
      alert('token not found to fullfill this request');
    }
  };

  const fetchData = () => {
    // get token from local storage
    const storedToken = localStorage.getItem('userToken');

    // check if token then parse into json and use it
    const token = storedToken ? JSON.parse(storedToken) : null;

    if (token) {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };
      Promise.all([
        fetch('https://quotationserver.aiodock.com/showQuotation', {
          method: 'POST',
          headers,
        }).then((result) => {
          if (result.status === 401) {
            handleLogout();
          }
          return result.json();
        }),
        fetch('https://quotationserver.aiodock.com/showClients', {
          method: 'GET',
          headers,
        }).then((result) => {
          if (result.status === 401) {
            handleLogout();
          }
          return result.json();
        }),
      ])
        .then(([quotationsResponse, clientsResponse]) => {
          const sortedQuotations = quotationsResponse.message.sort((a, b) => b.timestamp - a.timestamp);

          // Create a map to store client information using _id as the key
          const clientMap = {};
          clientsResponse.message.forEach((client) => {
            clientMap[client._id] = {
              phoneNumber: client.contactNo,
              company: client.company,
            };
          });

          // Associate client information with each quotation
          const quotationsWithClientInfo = sortedQuotations.map((quotation) => {
            const clientId = quotation.clientId;
            const clientInfo = clientMap[clientId] || {}; // Default to empty object if not found
            return {
              ...quotation,
              phoneNumber: clientInfo.phoneNumber,
              company: clientInfo.company,
            };
          });

          // Filter the quotations based on the current date
          const currentDate = dayjs().startOf('day');
          const todayQuotations = quotationsWithClientInfo.filter((quotation) => {
            const quotationDate = dayjs(quotation.date);
            return quotationDate.isSame(currentDate, 'day');
          });

          setQuotations(todayQuotations);
        })
        .catch((error) => {
          console.error('There was an error fetching quotations:', error);
        });
    } else {
      alert('token not found to fullfill this request');
    }
  };

  useEffect(() => {
    const endDate = dayjs().endOf('day');
    const startDate = dayjs().subtract(7, 'days').startOf('day');

    setSearchFrom(startDate);
    setSearchTo(endDate);
    fetchData();
    handleSearch();
  }, []);

  const handleSearch = () => {
    // get token from local storage
    const storedToken = localStorage.getItem('userToken');

    // check if token then parse into json and use it
    const token = storedToken ? JSON.parse(storedToken) : null;

    if (token) {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };
      Promise.all([
        fetch('https://quotationserver.aiodock.com/showQuotation', {
          method: 'POST',
          headers,
          body: JSON.stringify({
            from: searchFrom,
            to: searchTo,
          }),
        }).then((result) => {
          if (result.status === 401) {
            // handleLogout();
          }
          return result.json();
        }),
        fetch('https://quotationserver.aiodock.com/showClients', {
          method: 'GET',
          headers,
        }).then((result) => {
          // if (result.status === 401) {
          //   handleLogout();
          // }
          return result.json();
        }),
      ])
        .then(([quotationsResponse, clientsResponse]) => {
          const sortedQuotations = quotationsResponse.message.sort((a, b) => b.timestamp - a.timestamp);

          // Create a map to store client information using _id as the key
          const clientMap = {};
          clientsResponse.message.forEach((client) => {
            clientMap[client._id] = {
              phoneNumber: client.contactNo,
              company: client.company,
            };
          });

          // Associate client information with each quotation
          const quotationsWithClientInfo = sortedQuotations.map((quotation) => {
            const clientId = quotation.clientId;
            const clientInfo = clientMap[clientId] || {}; // Default to empty object if not found
            return {
              ...quotation,
              phoneNumber: clientInfo.phoneNumber,
              company: clientInfo.company,
            };
          });

          // Filter the quotations based on the search dates
          const filteredQuotations = quotationsWithClientInfo.filter((quotation) => {
            const quotationDate = dayjs(quotation.date);
            return quotationDate.isBetween(searchFrom, searchTo, null, '()');
          });

          setQuotations(filteredQuotations);
        })
        .catch((error) => {
          console.error('There was an error fetching quotations:', error);
        });
    } else {
      alert('token not found to fullfill this request');
    }
  };

  const handleDuplicate = (rowData) => {
    const currentDate = dayjs().toISOString();

    const duplicatedQuotation = {
      ...rowData,
      isDuplicated: true,
      timestamp: currentDate,
    };

    // get token from local storage
    const storedToken = localStorage.getItem('userToken');
    const token = storedToken ? JSON.parse(storedToken) : null;

    if (token) {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };
      confirmAlert({
        title: 'Duplicate',
        message: 'Are you sure you want to duplicate?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              fetch('https://quotationserver.aiodock.com/addQuotation', {
                method: 'POST',
                headers,
                body: JSON.stringify(duplicatedQuotation),
              })
                .then((response) => {
                  if (!response.ok) {
                    if (response.status === 401) {
                      // handleLogout();
                    } else {
                      throw new Error('Network response was not ok');
                    }
                  }
                  window.location.reload();
                })
                .catch((error) => {
                  // Handle error
                  console.error('There was an error adding the quotation:', error);
                });
            },
          },
          {
            label: 'No',
            onClick: () => {},
          },
        ],
      });
    } else {
      alert('token not found to fullfill this request');
    }
    // Add the duplicated quotation to the API
  };

  const createInvoice = (rowData) => {
    const invoice = { ...rowData, isDuplicated: true, timestamp: Date.now() };
    // get token from local storage
    const storedToken = localStorage.getItem('userToken');

    // check if token then parse into json and use it
    const token = storedToken ? JSON.parse(storedToken) : null;

    if (token) {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };
      confirmAlert({
        title: 'Invoice',
        message: 'Do you want to Create Invoice ?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              // fetch('https://quotationserver.aiodock.com/addInvoice', {
              fetch('https://quotationserver.aiodock.com/addInvoice', {
                method: 'POST',
                headers,
                body: JSON.stringify(rowData),
              })
                .then((response) => {
                  setInvoiceSnackbar(true);
                  if (!response.ok) {
                    if (response.status === 401) {
                      // handleLogout();
                    } else {
                      throw new Error('Network response was not ok');
                    }
                  }
                })
                .catch((error) => {
                  setInvoiceErrorSnackbar(true);
                });
            },
          },
          {
            label: 'No',
            onClick: () => {},
          },
        ],
      });
    } else {
      alert('token not found to fullfill this request');
    }
  };

  return (
    <>
      <Helmet>
        <title> Create | Quotation </title>
      </Helmet>

      <Container style={{ maxWidth: 'inherit' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Stack direction="row" alignItems="center">
              <Stack direction="row" spacing={2}>
                <TextField
                  label="From"
                  type="date"
                  value={searchFrom.format('YYYY-MM-DD')}
                  onChange={(event) => setSearchFrom(dayjs(event.target.value))}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  label="To"
                  type="date"
                  value={searchTo.format('YYYY-MM-DD')}
                  onChange={(event) => setSearchTo(dayjs(event.target.value))}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <Button size="sm" style={{}} variant="contained" onClick={() => handleSearch()}>
                  <Search />
                </Button>
              </Stack>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Button variant="contained" onClick={handleOpenModal} startIcon={<Iconify icon="eva:plus-fill" />}>
                Create Quotation
              </Button>
            </Stack>
          </Stack>
        </LocalizationProvider>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <MaterialTable
                title="Quotations"
                icons={tableIcons}
                columns={[
                  {
                    title: 'Date',
                    field: 'date',
                    defaultSort: 'desc',
                    render: (rowData) => new Date(rowData.date).toLocaleDateString(),
                  },
                  // { title: 'ID', field: 'clientId' },
                  { title: 'Client', field: 'clientName' },
                  { title: 'Phone', field: 'phoneNumber' },
                  { title: 'Company', field: 'company' },
                  { title: 'Module', field: 'module' },
                  { title: 'Category', field: 'category' },
                  { title: 'Width', field: 'screenWidth' },
                  { title: 'Height', field: 'screenHeight' },
                  // {
                  //   field: 'screenSize',
                  //   title: 'Screen Size',
                  //   width: '10%',
                  //   render: (rowData) => (
                  //     <Typography align="left">
                  //       {rowData.screenWidth} x {rowData.screenHeight}
                  //     </Typography>
                  //   ),
                  // },
                  { title: 'Rate', field: 'rate', render: (rowData) => muiComma(rowData.rate) },
                  {
                    title: 'Status',
                    field: 'status',
                    render: (rowData) => (
                      <div
                        style={{
                          color:
                            rowData.status === 'Sent / WIP'
                              ? '#9c27b0'
                              : rowData.status === 'Win'
                              ? 'Green'
                              : rowData.status === 'Loss'
                              ? 'Red'
                              : 'inherit',
                        }}
                      >
                        {rowData.status}
                      </div>
                    ),
                  },
                  { title: 'Remarks', field: 'remarks' },
                  {
                    title: 'Actions',
                    field: '',
                    render: (rowData) => (
                      <>
                        <IconButton
                          size="large"
                          color="inherit"
                          onClick={() => {
                            setEditData(rowData);
                            handleOpenEditModal();
                          }}
                        >
                          <Iconify icon={'eva:edit-fill'} />
                        </IconButton>
                        <IconButton
                          sx={{ color: 'primary' }}
                          size="large"
                          color="inherit"
                          onClick={() => {
                            setEditData(rowData);
                            handleOpenPDFModal();
                          }}
                        >
                          <Iconify icon={'material-symbols:print'} />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setEditData(rowData);
                            handleOpenLogsModal();
                          }}
                        >
                          <Iconify icon={'mdi:eye'} style={{ color: 'green' }} />
                        </IconButton>
                        <IconButton
                          sx={{ color: 'error.main' }}
                          size="large"
                          color="inherit"
                          onClick={() => handleDelete(rowData._id)}
                        >
                          <Iconify icon={'eva:trash-2-outline'} />
                        </IconButton>
                        <IconButton
                          sx={{ color: 'secondary.main' }}
                          size="large"
                          color="inherit"
                          onClick={() => handleDuplicate(rowData)}
                        >
                          <Iconify icon={'eva:copy-outline'} />
                        </IconButton>

                        <IconButton
                          sx={{ color: 'success.main' }}
                          size="large"
                          color="inherit"
                          onClick={() => createInvoice(rowData)}
                        >
                          <Iconify icon={'mdi:invoice'} />
                        </IconButton>
                      </>
                    ),
                  },
                ]}
                data={quotations}
                options={{
                  headerStyle: {
                    backgroundColor: '#2065D1',
                    color: '#FFF',
                  },
                  actionsColumnIndex: -1,
                  pageSize: 10,
                  pageSizeOptions: [20, 50, 100],
                  search: true,
                  exportButton: true,
                  sorting: true,

                  tableLayout: 'fixed',
                  fixedColumns: true,
                }}
                // options={{
                //   sorting: true,
                //   search: true,
                //   paging: false,
                //   pageSize: 10,
                //   exportButton: true,
                //   tableLayout: 'fixed',
                //   rowStyle: (rowData) => ({
                //     fontSize: 15,
                //   }),
                //   fixedColumns: true,
                //   headerStyle: () => ({
                //     backgroundColor: 'blue',
                //     color: 'white',
                //   }),
                // }}
              />
            </TableContainer>
          </Scrollbar>
        </Card>
        {openModal && <Qoutation onClose={handleCloseModal} />}
        {openEditModal && <EditQoutation data={editData} onClose={() => handleCloseEditModal()} />}
        {openPDFModal && <PrintPDFQoutation data={editData} onClose={handleClosePDFModal} />}
        {openLogsModal && <Logs data={editData} onClose={handleCloseLogsModal} />}

        <Snackbar
          open={openSnackbar}
          autoHideDuration={1500}
          onClose={() => setOpenSnackbar(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert variant="filled" severity="error" sx={{ width: '100%' }}>
            Quotation Deleted Successfully !
          </Alert>
        </Snackbar>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={1500}
          onClose={() => setOpenSnackbar(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert variant="filled" severity="error" sx={{ width: '100%' }}>
            Quotation Deleted Successfully !
          </Alert>
        </Snackbar>

        <Snackbar
          open={invoiceSnackbar}
          autoHideDuration={1500}
          onClose={() => setInvoiceSnackbar(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert variant="filled" severity="success" sx={{ width: '100%' }}>
            Invoice Created Successfully !
          </Alert>
        </Snackbar>

        <Snackbar
          open={invoiceErrorSnackbar}
          autoHideDuration={1500}
          onClose={() => setInvoiceErrorSnackbar(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert variant="filled" severity="error" sx={{ width: '100%' }}>
            Invoice Not Created. Plz Retry!
          </Alert>
        </Snackbar>
      </Container>
    </>
  );
}
