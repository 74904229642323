import * as React from 'react';
import { useState, useEffect } from 'react';
// date and time
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// @mui

import {
  Box,
  Stack,
  Button,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Container,
  Typography,
  TextField,
  Modal,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  CssBaseline,
  Autocomplete,
  Snackbar,
  Alert,
  DialogTitle,
  Divider,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// components

export default function EditInvoice(props) {
  // date n timne
  const [value, setValue] = React.useState(dayjs('2014-08-18T21:11:54'));
  const [leaveFrom, setLeaveFrom] = React.useState(dayjs('2014-08-18T21:11:54'));
  const [leaveTo, setLeaveTo] = React.useState(dayjs('2014-08-18T21:11:54'));
  const [date, setDate] = React.useState(new Date());
  const [formData, setFormData] = useState(props.data);
  const [newModule, setNewModule] = useState(formData.module);
  const [newCategory, setNewCategory] = useState(formData.category);
  const [newBrand, setNewBrand] = useState(formData.brand);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [moduleSnackbar, setModuleSnackbar] = useState(false);

  // const [rate, setRate] = React.useState(0);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  // ---------------------Modal-------------------------------
  const [openModal, setOpenModal] = useState(true);
  //   const [open, setOpen] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    props.handleCloseEditModal();
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 1150,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: '95%',
  };
  const [openEditModal, setOpenEditModal] = useState(true);
  const theme = createTheme();
  //   ----- Image -----
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedData = {
      ...formData,
    };
    // get token from local storage
    const storedToken = localStorage.getItem('userToken');

    // check if token then parse into json and use it
    const token = storedToken ? JSON.parse(storedToken) : null;

    if (token) {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };
      fetch(`https://quotationserver.aiodock.com/updateInvoice/${formData._id}`, {
        method: 'PUT',
        body: JSON.stringify(updatedData),
        headers,
      })
        .then((response) => response.json())
        .then((data) => {
          setFormData({
            PON: '',
            remarks: '',
          });
          setOpenSnackbar(true);
        })
        .catch((error) => {
          console.error('Update failed:', error);
          // handle error response
        });
    } else {
      alert('token not found to fullfill this request');
    }
  };

  useEffect(() => {
    if (openSnackbar) {
      const snackbarTimer = setTimeout(() => {
        props.onClose();
      }, 1500);

      return () => {
        clearTimeout(snackbarTimer);
      };
    }
    return undefined;
  }, [openSnackbar]);

  const handleChangeData = (event) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };
  return (
    <>
      <Container>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Modal open={openModal} onClose={props.onClose}>
              <Box sx={style}>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <DialogTitle id="customized-dialog-title" sx={{ textAlign: 'center' }}>
                    UPDATE INVOICE -<span style={{ color: 'red' }}>{formData.clientName}</span>-
                  </DialogTitle>
                  <Divider fullWidth />
                  <Box component="form" onSubmit={handleSubmit} noValidate style={{ width: '100%' }}>
                    <Grid container>
                      <Grid item={5}>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          style={{ width: '38em', paddingRight: 8 }}
                          type="text"
                          label="Client Name"
                          name="clientName"
                          autoFocus
                          value={formData.clientName}
                          onChange={handleChangeData}
                        />
                      </Grid>
                      <Grid item={2}>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          style={{ width: '19em', paddingRight: 8 }}
                          name="PON"
                          value={formData.PON}
                          onChange={handleChangeData}
                          label="PO No."
                          type="text"
                        />
                      </Grid>
                      <Grid item={2}>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          style={{ width: '19em', paddingRight: 8 }}
                          name="advancePayment"
                          value={formData.advancePayment}
                          onChange={handleChangeData}
                          label="Advance Payment"
                          type="number"
                        />
                      </Grid>

                      <Grid item={2}>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          style={{ width: '19em', paddingRight: 8 }}
                          onChange={handleChangeData}
                          label="Contact No."
                          name="ContactNo"
                          type="text"
                          autoFocus
                          value={formData.contactNo}
                        />
                      </Grid>
                      <Grid item={2}>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          style={{ width: '19em', paddingRight: 8 }}
                          onChange={handleChangeData}
                          label="Address"
                          name="clientAddress"
                          autoFocus
                          type="text"
                          value={formData.clientAddress}
                        />
                      </Grid>
                      <Grid item={2}>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          style={{ width: '15em', paddingRight: 8 }}
                          type="text"
                          label="City"
                          name="clientCity"
                          autoFocus
                          value={formData.clientCity}
                          onChange={handleChangeData}
                        />
                      </Grid>

                      {/* <Grid item={3}>
                        <FormControl>
                          <FormLabel id="demo-row-radio-buttons-group-label">GST</FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="gst"
                            value={formData.gst}
                            onChange={handleChangeData}
                          >
                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel value="no" control={<Radio />} label="No" />
                          </RadioGroup>
                        </FormControl>
                      </Grid> */}

                      <Grid item={2}>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          style={{ width: '38em' }}
                          name="remarks"
                          value={formData.remarks}
                          onChange={handleChangeData}
                          label="Remarks"
                          type="text"
                          rows={5}
                          multiline
                        />
                      </Grid>
                    </Grid>

                    <Box sx={{ display: 'flex' }}>
                      <Button type="submit" color="secondary" variant="contained" sx={{ mb: 2, width: '10%' }}>
                        Update
                      </Button>
                      <Button onClick={props.onClose} variant="outlined" sx={{ mb: 2, mx: 3, width: '10%' }}>
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                </ThemeProvider>
                <Snackbar
                  open={openSnackbar}
                  autoHideDuration={3000} // set the Snackbar to auto-hide after 3 seconds
                  onClose={() => setOpenSnackbar(false)} // set the function to close the Snackbar when it is clicked
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                  <Alert variant="filled" severity="success" sx={{ width: '100%' }}>
                    Invoice Updated Succesfully!
                  </Alert>
                </Snackbar>
                <Snackbar
                  open={moduleSnackbar}
                  autoHideDuration={1500}
                  onClose={() => setModuleSnackbar(false)}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                  <Alert variant="filled" severity="error" sx={{ width: '100%' }}>
                    Module Not Exists. <br />
                    Please Change its Category or Brand.
                  </Alert>
                </Snackbar>
              </Box>
            </Modal>
          </Stack>
        </LocalizationProvider>
      </Container>
    </>
  );
}
