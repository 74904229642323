import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useState, useEffect, forwardRef } from 'react';
import MaterialTable from 'material-table';
import { Card, Stack, Button, Container, Typography, IconButton, TableContainer, Snackbar, Alert } from '@mui/material';

// Confirm Delete Import
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
// Material Table Icons Imports
import AddBox from '@mui/icons-material/AddBox';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';
import { CircularProgress } from '@material-ui/core';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';

// User Imports
import NewUser from '../layouts/Clients/NewClient';
import EditNewUser from '../layouts/Clients/EditClient';

// Material Table Icons Defined
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
export default function Users() {
  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState(null);
  // ->> Open Success
  const [success, setSuccess] = useState(false);
  const [successText, setSuccessText] = useState('Client Added Successfully !');
  // ->> Open Error
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('Invalid Details');

  // ---------------------Dialog-------------------------------
  const [openDialog, setOpenDialog] = useState(null);
  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => {
    setOpenDialog(false);
    fetchData();
  };

  // --------------------- Edit Dialog--------------------------
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const handleOpenEditDialog = () => setOpenEditDialog(true);
  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    fetchData();
  };

  const handleDelete = (_id) => {
    // show the confirmation alert before deleting the row data
    const storedToken = localStorage.getItem('userToken');

    // check if token then parse into JSON and use it
    const token = storedToken ? JSON.parse(storedToken) : null;

    if (token) {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };
      confirmAlert({
        title: 'Confirm',
        message: 'Are you sure you want to delete this Client ?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              fetch(`https://quotationserver.aiodock.com/deleteClient/${_id}`, {
                method: 'DELETE',
                headers,
              })
                .then((response) => {
                  if (!response.ok) {
                    throw new Error('Network response was not ok');
                  }
                  handleSuccess('Client Deleted Successfully !');
                })
                .catch((error) => {
                  // Handle error
                  console.error('There was an error deleting the employee:', error);
                })
                .finally(() => {
                  handleSuccess('Client Deleted Successfully !');
                  fetchData();
                });
            },
          },
          {
            label: 'No',
            onClick: () => {},
          },
        ],
      });
    } else {
      alert('token not found to delete, login first');
    }
  };

  const handleLogout = () => {
    setUsers([]);
    const confirmLogout = window.confirm('Your session has expired. Do you want to log out?');
    if (confirmLogout) {
      localStorage.removeItem('userToken');
      navigate('/login');
    }
  };
  const fetchData = () => {
    setLoading(true);
    const storedToken = localStorage.getItem('userToken');
    const token = storedToken ? JSON.parse(storedToken) : null;

    if (token) {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };

      fetch('https://quotationserver.aiodock.com/showClients', {
        method: 'GET',
        headers,
      })
        .then((result) => {
          if (result.status === 401) {
            handleLogout();
          } else if (result.ok) {
            result.json().then((response) => {
              if (response && response.message && response.message.length > 0) {
                setUsers(response.message);
                setLoading(false);
              }
            });
          } else {
            // Handle other error cases, e.g., network issues or non-401 status codes
            console.error('Error fetching data:', result.status);
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    } else {
      alert('Token is not available. Please log in again.');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // -->>  Function for Error
  const handleError = (errorText) => {
    setError(true);
    setErrorText(errorText);
  };

  // -->>  Function for Success
  const handleSuccess = (successText) => {
    setSuccess(true);
    setSuccessText(successText);
  };

  // -->> Function to close the Snackbar
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(false);
    setSuccess(false);
  };

  return (
    <>
      <Helmet>
        <title> AioDock | Clients </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Clients Information
          </Typography>
          <Button variant="contained" onClick={handleOpenDialog} startIcon={<Iconify icon="eva:plus-fill" />}>
            New Client
          </Button>
        </Stack>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <MaterialTable
                icons={tableIcons}
                title="Clients"
                columns={[
                  { title: 'Client Name', field: 'clientName', defaultSort: 'asc' },
                  { title: 'Designation', field: 'clientDesignation' },
                  { title: 'Contact No', field: 'contactNo' },
                  { title: 'Client Address', field: 'clientAddress' },
                  { title: 'Company', field: 'company' },
                  // { title: 'Country', field: 'country' },
                  {
                    title: 'Actions',
                    field: '',
                    render: (rowData) => (
                      <>
                        <IconButton
                          size="large"
                          color="inherit"
                          onClick={() => {
                            setEditData(rowData);
                            handleOpenEditDialog();
                          }}
                        >
                          <Iconify icon={'eva:edit-fill'} />
                        </IconButton>
                        {/* <IconButton sx={{ color: 'primary' }} size="large" color="inherit">
                            <Iconify icon={'material-symbols:print'} />
                          </IconButton> */}
                        <IconButton
                          sx={{ color: 'error.main' }}
                          size="large"
                          color="inherit"
                          onClick={() => handleDelete(rowData._id)}
                        >
                          <Iconify icon={'eva:trash-2-outline'} />
                        </IconButton>
                      </>
                    ),
                  },
                ]}
                data={users}
                isLoading={loading}
                options={{
                  headerStyle: {
                    backgroundColor: '#01579B',
                    color: '#FFF',
                    whiteSpace: 'nowrap',
                    fontSize: '0.8em',
                  },
                  rowStyle: (rowData, index) => ({
                    backgroundColor: index % 2 === 0 ? '#F9F9F9' : '#FFF',
                    height: '5em',
                    fontSize: '0.8em',
                  }),
                  actionsColumnIndex: -1,
                  sorting: true,
                  search: true,
                  paging: true,
                  exportButton: true,
                  pageSize: 10,
                  pageSizeOptions: false,
                  showFirstLastPageButtons: false,
                  exportAllData: true,
                }}
              />
            </TableContainer>
          </Scrollbar>
        </Card>

        {openDialog && (
          <NewUser
            open={handleOpenDialog}
            handleClose={handleCloseDialog}
            handleSuccess={handleSuccess}
            handleError={handleError}
          />
        )}
        {openEditDialog && (
          <EditNewUser
            data={editData}
            // onClose={handleCloseEditDialog}
            open={handleOpenDialog}
            handleClose={handleCloseEditDialog}
            handleSuccess={handleSuccess}
            handleError={handleError}
          />
        )}

        <Snackbar
          open={success}
          autoHideDuration={2000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert variant="filled" severity="success" sx={{ width: '100%' }}>
            {successText}
          </Alert>
        </Snackbar>
        <Snackbar
          open={error}
          autoHideDuration={2000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert variant="filled" severity="error" sx={{ width: '100%' }}>
            {errorText}
          </Alert>
        </Snackbar>
      </Container>
    </>
  );
}
