import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import { Box, List, ListItemText, ListItemButton, Collapse } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { StyledNavItem, StyledNavItemIcon } from './styles';

NavSection.propTypes = {
  data: PropTypes.array,
};

// ... (previous imports)

export default function NavSection({ data = [], ...other }) {
  const [open, setOpen] = useState(false);
  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    // Retrieve role from localStorage
    const role = localStorage.getItem('role');
    setUserRole(role);
  }, []); // Run only once on mount

  useEffect(() => {
    // Update userRole whenever it changes
    const role = localStorage.getItem('role');
    setUserRole(role);
  }, [userRole]); // Re-run when userRole changes

  const filteredData = data.filter((item) => {
    // Only show items that match the user's role or have no role specified
    return !item.role || item.role.includes(userRole);
  });

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {filteredData.map((item) => {
          return <NavItem key={item.title} item={item} />;
        })}
      </List>
    </Box>
  );
}

// ... (remaining code)


NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
      <ListItemText disableTypography primary={title} />
      {info && info}
    </StyledNavItem>
  );
}
