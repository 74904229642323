// ----------------------------------------------------------------------

const account = {
  displayName: 'AioDock Quotation',
  email: 'info@aiodock.com',
  photoURL: '/assets/images/avatars/avatar_default.jpg',
  // role: 'user',
};

export default account;
