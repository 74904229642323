import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
// Mui Imports
import { Card, Stack, Container, Typography, TextField, Grid } from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';

export default function Calculator() {
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');

  const handleModulesWidthChange = (event) => {
    const numberOfModulesInWidth = event.target.value;
    setWidth(numberOfModulesInWidth);

    //  Without Frame
    // Convert to  inches (Width)
    const widthWithoutFrameInches = numberOfModulesInWidth * (320 / 25.4).toFixed(2);
    document.getElementById('width-without-frame-inches').value = widthWithoutFrameInches;

    // Convert to feet (Width)
    const widthWithoutFrameFeet = (widthWithoutFrameInches / 12).toFixed(2);
    document.getElementById('width-without-frame-feet').value = widthWithoutFrameFeet;

    // With Frame
    // Convert to Inches (width)
    const widthWithFrameInches = (numberOfModulesInWidth * (320 / 25.4) + 2).toFixed(2);
    document.getElementById('width-with-frame-inches').value = widthWithFrameInches;

    // Convert to Feet(width)
    const widthWithFrameFeet = (widthWithFrameInches / 12).toFixed(2);
    document.getElementById('width-with-frame-feet').value = widthWithFrameFeet;
  };

  const handleModulesHeightChange = (event) => {
    const numberOfModulesInHeight = event.target.value;
    setHeight(numberOfModulesInHeight);

    //  Without Frame
    // Convert to inches (Height)
    const heightWithoutFrameInches = numberOfModulesInHeight * (160 / 25.4).toExponential(2);
    document.getElementById('height-without-frame-inches').value = heightWithoutFrameInches;

    // Convert to feet (Height)
    const heightWithoutFrameFeet = (heightWithoutFrameInches / 12).toFixed(2);
    document.getElementById('height-without-frame-feet').value = heightWithoutFrameFeet;

    // With Frame
    // Convert to Inches (height)
    const heightWithFrameInches = (numberOfModulesInHeight * (160 / 25.4) + 2).toFixed(2);
    document.getElementById('height-with-frame-inches').value = heightWithFrameInches;

    // Convert to Feet(height)
    const heightWithFrameFeet = (heightWithFrameInches / 12).toFixed(2);
    document.getElementById('height-with-frame-feet').value = heightWithFrameFeet;
  };

  return (
    <>
      <Helmet>
        <title> Calculator </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Calculator
          </Typography>
        </Stack>

        <Card>
          <Grid container>
            {/* Text Fields for Width  */}
            <Grid xs={6}>
              <Grid item xs={12}>
                <TextField
                  margin="normal"
                  required
                  style={{ marginLeft: '1em', marginBottom: '2em' }}
                  name="width"
                  label="No. of Modules in Width"
                  type="text"
                  value={width}
                  onChange={handleModulesWidthChange}
                />
              </Grid>

              <Grid>
                <TextField
                  type="text"
                  name="width-without-frame-inches"
                  id="width-without-frame-inches"
                  label="Width (inches)"
                  defaultValue="0"
                  helperText="Without Frame"
                  style={{ width: '30em', marginLeft: '1em', marginRight: '1em', marginBottom: '2em' }}
                />
              </Grid>

              <Grid>
                <TextField
                  type="text"
                  name="width-without-frame-feet"
                  id="width-without-frame-feet"
                  label="Width (feets)"
                  defaultValue="0"
                  helperText="Without Frame"
                  style={{ width: '30em', marginLeft: '1em', marginRight: '1em', marginBottom: '2em' }}
                />
              </Grid>

              <Grid>
                <TextField
                  type="text"
                  name="width-with-frame-inches"
                  id="width-with-frame-inches"
                  label="Width (inches)"
                  defaultValue="0"
                  helperText="With Frame"
                  style={{ width: '30em', marginLeft: '1em', marginRight: '1em', marginBottom: '2em' }}
                />
              </Grid>
              <Grid>
                <TextField
                  type="text"
                  name="width-with-frame-feet"
                  id="width-with-frame-feet"
                  label="Width (feets)"
                  defaultValue="0"
                  helperText="With Frame"
                  style={{ width: '30em', marginLeft: '1em', marginRight: '1em', marginBottom: '2em' }}
                />
              </Grid>
            </Grid>

            {/* < --------------------------------------------------------- > */}

            {/* Text Fields for Height */}
            <Grid xs={6}>
              <Grid item xs={12}>
                <TextField
                  margin="normal"
                  required
                  style={{ marginLeft: '1em', marginBottom: '2em' }}
                  name="height"
                  label="No. of Modules in Height"
                  type="text"
                  value={height}
                  onChange={handleModulesHeightChange}
                />
              </Grid>
              <Grid>
                <TextField
                  type="text"
                  name="height-without-frame-inches"
                  id="height-without-frame-inches"
                  label="Height (inches)"
                  defaultValue="0"
                  helperText="Without Frame"
                  style={{ width: '29em', marginLeft: '1em', marginBottom: '2em' }}
                />
              </Grid>

              <Grid>
                <TextField
                  type="text"
                  name="height-without-frame-feet"
                  id="height-without-frame-feet"
                  label="Height (feets)"
                  defaultValue="0"
                  helperText="Without Frame"
                  style={{ width: '29em', marginLeft: '1em', marginBottom: '2em' }}
                />
              </Grid>
              <Grid>
                <TextField
                  type="text"
                  name="height-with-frame-inches"
                  id="height-with-frame-inches"
                  label="Height (inches)"
                  defaultValue="0"
                  helperText="With Frame"
                  style={{ width: '29em', marginLeft: '1em', marginBottom: '2em' }}
                />
              </Grid>

              <Grid>
                <TextField
                  type="text"
                  name="height-with-frame-feet"
                  id="height-with-frame-feet"
                  label="Height (feets)"
                  defaultValue="0"
                  helperText="With Frame"
                  style={{ width: '29em', marginLeft: '1em', marginBottom: '2em' }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Container>
    </>
  );
}
