// component
import { React } from 'react';
import SvgColor from '../../../components/svg-color';
import NavIcons from '../../../components/NavIcons';

// ----------------------------------------------------------------------

// const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;
const icon = (name) => <NavIcons icon={name} width={22} height={22} />;
const navConfig = [
  // {
  //   title: 'dashboard',
  //   path: '/dashboard/app',
  //   icon: icon('ic_analytics'),
  //   type: 'simple',
  // },
  {
    title: 'Clients',
    path: '/dashboard/clients',
    icon: icon('ri:user-fill'),
    type: 'simple',
    role: ['user', 'admin'],
  },
  {
    title: 'Quotations',
    path: '/dashboard/quotations',
    icon: icon('zondicons:news-paper'),
    type: 'simple',
    role: ['user', 'admin'],
  },

  {
    title: 'Modules',
    path: '/dashboard/modules',
    icon: icon('teenyicons:screen-alt-solid'),
    type: 'simple',
    role: ['user', 'admin'],
  },
  {
    title: 'Invoice',
    path: '/dashboard/invoice',
    icon: icon('mdi:invoice'),
    type: 'simple',
    role: ['user', 'admin'],
  },
  {
    title: 'Calculator',
    path: '/dashboard/calculator',
    icon: icon('bi:calculator'),
    type: 'simple',
    role: ['user', 'admin'],
  },
  {
    title: 'Users',
    path: '/dashboard/users',
    icon: icon('mdi:user'),
    type: 'simple',
    role: ['admin'],
  },

  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: icon('ic_cart'),
  //   type: 'simple',
  // },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: icon('ic_blog'),
  //   type: 'simple',
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  //   type: 'simple',
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  //   type: 'simple',
  // },
];

export default navConfig;
