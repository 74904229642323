import { Link as RouterLink } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Card, Link, Container, Typography } from "@mui/material";
// hooks
import useResponsive from "../hooks/useResponsive";
// components
import Page from "../components/Page";
// sections
import { LoginForm } from "../sections/auth/login";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
    backgroundColor:'white'
  },
}));

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 564,
  display: "flex",
  height: "100%",
  flexDirection: "column",
  textAlign: "-webkit-center",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");

  return (
    <Page title="Login">
      <RootStyle>
        {mdUp && (
          <SectionStyle>
          <img src="/assets/illustrations/aiodock_illustrations.jpg" alt="login" />
          </SectionStyle>
        )}

        <Container maxWidth="md">
          <ContentStyle>
            <Typography
              variant="h3"
              gutterBottom
              style={{ color: "rgb(32,55,100)" }}
            >
              AioDock Digital
            </Typography>
            <Typography
              variant="h4"
              gutterBottom
              style={{ color: "rgb(100,55,100)" }}
            >
              Quotation Portal & Client Management System
            </Typography>
            <Typography variant="h5" gutterBottom style={{ color: "red" }}>
              Powered by AIODOCK
            </Typography>

            <Typography variant="h5" gutterBottom>
              Login
            </Typography>

            <LoginForm />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
