import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useState, useEffect, forwardRef } from 'react';
import MaterialTable from 'material-table';
import { Card, Stack, Button, Container, Typography, IconButton, TableContainer, Snackbar, Alert } from '@mui/material';

// Confirm Delete Import
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

// Material Table Icons Imports
import AddBox from '@mui/icons-material/AddBox';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';
import { CircularProgress } from '@material-ui/core';

import { useNavigate } from 'react-router-dom';
// User Imports
import NewUser from '../layouts/Users/NewUsers';
import EditUser from '../layouts/Users/EditUsers';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// Comma Function
import muiComma from '../components/commaFunction';

// Material Table Icons Defined
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
export default function Users() {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  // Hooks
  const [module, setModule] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [editData, setEditData] = useState(null);

  // ---------------------Modal-------------------------------
  const [openModal, setOpenModal] = useState(null);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setModule();
    fetchData();
  };

  // --------------------- Edit Modal--------------------------
  const [openEditModal, setOpenEditModal] = useState(false);
  const handleOpenEditModal = () => setOpenEditModal(true);
  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    fetchData();
  };

  const navigate = useNavigate();

  const handleLogout = () => {
    setModule([]);
    const confirmLogout = window.confirm('Your session has expired. Do you want to log out?');
    if (confirmLogout) {
      localStorage.removeItem('userToken');
      navigate('/login');
    }
  };
  const handleDelete = (_id) => {
    const storedToken = localStorage.getItem('userToken');
    const token = storedToken ? JSON.parse(storedToken) : null;

    if (token) {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };
      confirmAlert({
        title: 'Confirm',
        message: 'Are you sure you want to delete this User ?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              fetch(`https://quotationserver.aiodock.com/deleteUser/${_id}`, {
                method: 'DELETE',
                headers,
              })
                .then((response) => {
                  if (response.status === 401) {
                    handleLogout();
                  } else {
                    throw new Error('Network response was not ok');
                  }
                  // Remove the deleted row from the module state
                  setModule((prevUsers) => prevUsers.filter((user) => user._id !== _id));
                  setOpenSnackbar(true);
                })
                .catch((error) => {
                  // Handle error
                  console.error('There was an error deleting smd:', error);
                });
            },
          },
          {
            label: 'No',
            onClick: () => {},
          },
        ],
      });
    } else {
      alert('token not found to fullfill this request');
    }
  };

  // Fetching Data from an API
  const fetchData = () => {
    const storedToken = localStorage.getItem('userToken');
    const token = storedToken ? JSON.parse(storedToken) : null;

    if (token) {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };
      // fetch('https://quotationserver.aiodock.com/getUsers', {
      fetch('https://quotationserver.aiodock.com/getUsers', {
        method: 'GET',
        headers,
      })
        .then((result) => {
          if (result.status === 401) {
            handleLogout();
          } else if (result.ok) {
            result.json().then((response) => {
              if (response && response.message && response.message.length > 0) {
                setModule(response.users);
                setIsLoading(false);
              }
            });
          } else {
            console.error('Error fetching data:', result.status);
          }
        })
        .catch((error) => {
          console.error('There was an error fetching data:', error);
        });
    } else {
      alert('token not found to fullfill this request');
    }
  };

  console.log('Modules are', module);
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title> AioDock | Users </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Users
          </Typography>
          <Button variant="contained" onClick={handleOpenModal} startIcon={<Iconify icon="eva:plus-fill" />}>
            Add New User
          </Button>
        </Stack>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <MaterialTable
                icons={tableIcons}
                title="All Users"
                columns={[
                  { title: 'Name', field: 'userName', defaultSort: 'asc' },
                  { title: 'Email', field: 'email' },
                  { title: 'Password', field: 'userPassword' },
                  {
                    title: 'Role',
                    field: 'role',
                    render: (rowData) => {
                      return <>{rowData.role === 'admin' ? 'Admin' : 'User'}</>;
                    },
                  },
                  {
                    title: 'Actions',
                    field: '',
                    render: (rowData) => (
                      <>
                        <IconButton
                          size="large"
                          color="inherit"
                          onClick={() => {
                            setEditData(rowData);
                            handleOpenEditModal();
                          }}
                        >
                          <Iconify icon={'eva:edit-fill'} />
                        </IconButton>

                        <IconButton
                          sx={{ color: 'error.main' }}
                          size="large"
                          color="inherit"
                          onClick={() => handleDelete(rowData._id)}
                        >
                          <Iconify icon={'eva:trash-2-outline'} />
                        </IconButton>
                      </>
                    ),
                  },
                ]}
                data={module}
                options={{
                  headerStyle: {
                    backgroundColor: '#2065D1',
                    color: '#FFF',
                  },
                  sorting: true,
                  search: true,
                  paging: true,
                  pageSize: 10,
                }}
              />
            </TableContainer>
          </Scrollbar>
        </Card>

        {openModal && <NewUser onClose={handleCloseModal} />}
        {openEditModal && <EditUser data={editData} onClose={handleCloseEditModal} />}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={1500}
          onClose={() => setOpenSnackbar(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert variant="filled" severity="error" sx={{ width: '100%' }}>
            Module Deleted Successfully !
          </Alert>
        </Snackbar>
      </Container>
    </>
  );
}
