import * as React from 'react';
import { useState, useEffect } from 'react';
// date and time
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// @mui

import {
  Box,
  Stack,
  Button,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Container,
  Typography,
  TextField,
  Modal,
  Grid,
  DialogTitle,
} from '@mui/material';
// components
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image, Font } from '@react-pdf/renderer';

import logo from '../Images/logo.png';

Font.register({ src: 'https://fonts.gstatic.com/s/kadwa/v2/rnCm-x5V0g7ipiTAT8M.ttf', family: 'KadwaRegular' });
Font.register({ src: 'https://fonts.gstatic.com/s/kadwa/v2/rnCr-x5V0g7ipix7atM5kn0.ttf', family: 'KadwaBold' });
Font.register({
  src: 'https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459Wlhzg.ttf',
  family: 'MontserratRegular',
});
Font.register({
  src: '../Quotation/Fonts/RobotoSerif-VariableFont_GRAD,opsz,wdth,wght.ttf',
  family: 'RobotoRegular',
});
Font.register({
  src: 'https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmWUlfBBc9.ttf',
  family: 'RobotoBold',
});

export default function PrintInvoice(props) {
  const [details, setDetails] = useState(props.data);
  // date n timne
  const [value, setValue] = React.useState(dayjs('2014-08-18T21:11:54'));
  const [leaveFrom, setLeaveFrom] = React.useState(dayjs('2014-08-18T21:11:54'));
  const [leaveTo, setLeaveTo] = React.useState(dayjs('2014-08-18T21:11:54'));

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  // ---------------------Modal-------------------------------
  const [openModal, setOpenModal] = useState(true);
  //   const [open, setOpen] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: '95%',
  };
  const [openEditModal, setOpenEditModal] = useState(true);
  const [closeEditModal, setCloseEditModal] = useState(false);

  const styles = StyleSheet.create({
    couple: {
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'center',
    },
    huidu: {
      width: 100,
      height: 30,
      marginLeft: 40,
      // marginTop: 12.5,
      // marginBottom: 12.5,
    },
    No1Brand: {
      width: 80,
      height: 30,
    },
    genergy: {
      width: 50,
      height: 30,
      marginLeft: 65,
      // marginTop: 5,
      // marginBottom: 5,
    },
    unilumin: {
      width: 100,
      height: 30,
      marginLeft: 35,
      // marginTop: 5,
      // marginBottom: 5,
    },
    fiba: {
      width: 80,
      height: 30,
    },
    logo: {
      width: 100,
      height: 60,
      paddingLeft: 10,
    },
    auth: {
      width: 120,
      height: 50,
      position: 'absolute',
      marginTop: 100,
      marginLeft: 30,
    },
    header: {
      borderTopWidth: 4,
      borderTopColor: '#0084B4',
      color: '#898989',
      padding: 20,
      display: 'flex',
      flexDirection: 'row',
    },
    headerLeft: {
      textAlign: 'left',
      flex: 1,
      alignSelf: 'center',
      fontSize: 32,
      fontWeight: 900,
      lineHeight: 1,
      color: '#0084B4',
      fontFamily: 'KadwaBold',
    },
    headerRight: {
      textAlign: 'right',
      fontSize: 12,
      flex: 1,
      // fontFamily: 'MontserratRegular',
    },

    ribbon: {
      backgroundColor: '#0084B4',
      display: 'flex',
      flexDirection: 'row',
      padding: 20,
      marginBottom: 20,
      textAlign: 'center',
      color: '#FFF',
    },
    ribbonGrey: {
      backgroundColor: '#EDEDED',
      display: 'flex',
      flexDirection: 'row',
      padding: 20,
      marginBottom: 10,
      textAlign: 'center',
      color: '#0084B4',
    },
    ribbonBox: {
      width: '33.333333%',
    },
    client: {
      fontSize: 14,
      fontFamily: 'KadwaRegular',
    },
    ribbonLabel: {
      fontSize: 14,
      fontFamily: 'KadwaBold',
    },
    pon: {
      fontSize: 14,
      fontFamily: 'KadwaBold',
      color: 'red',
    },
    ribbonValue: {
      fontSize: 16,
      fontFamily: 'KadwaBold',
      color: 'red',
    },
    ribbonValue1: {
      fontSize: 16,
      fontFamily: 'KadwaBold',
    },

    table: {
      //   paddingHorizontal: 20,
      display: 'flex',
      marginBottom: 10,
    },
    tableRowA: {
      backgroundColor: '#EDEDED',
      display: 'flex',
      flexDirection: 'row',
      paddingLeft: 10,
      //   padding: 10,
    },
    tableRowB: {
      //   padding: 10,
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'row',
    },
    serviceName: {
      fontSize: 9,
      width: '25%',
      fontFamily: 'KadwaBold',
    },
    serviceDescription: {
      fontSize: 8,
      width: '30%',
      // fontFamily: 'MontserratRegular',
    },
    differ: {
      fontSize: 9,
      fontFamily: 'KadwaRegular',
      Left: '20',
    },
    module: {
      fontSize: 12,
      width: '40%',
      color: 'red',
      fontFamily: 'KadwaBold',
      paddingLeft: 10,
    },
    cardsInfo: {
      fontSize: 8,
      // width: '10%',
      textAlign: 'left',
      fontFamily: 'KadwaBold',
    },
    cardsInfoData: {
      fontSize: 8,
      // width: '10%',
      marginLeft: 10,
      textAlign: 'left',
      fontFamily: 'KadwaRegular',
    },
    fullColor: {
      fontSize: 7,
      // width: '10%',
      textAlign: 'left',
      fontFamily: 'KadwaBold',
    },
    serviceAmount: {
      fontSize: 9,
      width: '15%',
      textAlign: 'center',
      fontFamily: 'KadwaBold',
    },
    serviceAmountLast: {
      fontSize: 9,
      width: '25%',
      textAlign: 'center',
      fontFamily: 'KadwaBold',
    },
    tableHeadingA: {
      width: '30%',
      fontSize: 9,
      textAlign: 'center',
      color: '#0084B4',
      fontFamily: 'KadwaBold',
      paddingLeft: 10,
    },

    tableHeadingB: {
      width: '10%',
      fontSize: 9,
      textAlign: 'center',
      color: '#0084B4',
      fontFamily: 'KadwaBold',
    },
    tableHeadingC: {
      width: '8%',
      textAlign: 'center',
      fontSize: 9,
      color: '#0084B4',
      fontFamily: 'KadwaBold',
    },
    tableHeadingD: {
      width: '10%',
      fontSize: 9,
      textAlign: 'center',
      color: '#0084B4',
      fontFamily: 'KadwaBold',
    },
    tableHeadingE: {
      width: '15%',
      fontSize: 9,
      textAlign: 'center',
      color: '#0084B4',
      fontFamily: 'KadwaBold',
    },
    tableHeadingF: {
      width: '10%',
      fontSize: 9,
      textAlign: 'center',
      color: '#0084B4',
      fontFamily: 'KadwaBold',
    },
    tableHeadingG: {
      width: '17%',
      fontSize: 9,
      textAlign: 'center',
      color: '#0084B4',
      fontFamily: 'KadwaBold',
    },
    tableHeadingLast: {
      width: '15%',
      textAlign: 'right',
      fontSize: 9,
      color: '#0084B4',
      fontFamily: 'KadwaBold',
    },
    summary: {
      backgroundColor: '#EDEDED',
      color: '#FFF',
      padding: 5,
      paddingLeft: 10,
      paddingRight: 10,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'left',
    },
    summaryMeta: {
      width: '40%',
      fontSize: 15,
      color: '#0084B4',
      fontFamily: 'KadwaBold',
    },
    summaryAmount: {
      fontSize: 15,
      width: '60%',
      color: 'black',
      textAlign: 'right',
      fontFamily: 'KadwaBold',
    },

    howToPay: {
      paddingHorizontal: 20,
      textAlign: 'center',
      fontSize: 15,
      color: '#0084B4',
      fontFamily: 'KadwaBold',
    },
    ltd: {
      textAlign: 'left',
      fontSize: 16,
      color: '#0084B4',
      fontFamily: 'KadwaBold',
    },
    TermsCondition: {
      paddingHorizontal: 20,
      textAlign: 'center',
      fontSize: 15,
      color: 'black',
      fontFamily: 'KadwaBold',
    },
    TermsConditionHeadings: {
      paddingLeft: 20,
      textAlign: 'left',
      fontSize: 9,
      color: 'black',
      fontFamily: 'Regular',
    },
    TermsConditionLine: {
      paddingLeft: 20,
      textAlign: 'left',
      fontSize: 9,
      color: 'black',
      fontFamily: 'KadwaRegular',
    },
    TermsConditionLineHeading: {
      // paddingLeft: 20,
      // textAlign: 'left',
      // fontSize: 9,
      // color: 'black',
      fontFamily: 'KadwaBold',
    },

    footer: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      padding: 20,
      borderTopColor: '#EDEDED',
      borderTopWidth: 1,
      textAlign: 'center',
      fontSize: 9,
      fontFamily: 'KadwaRegular',
    },
  });
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };
  const dueDate = new Date(details.date);
  dueDate.setDate(dueDate.getDate() + 5);

  return (
    <>
      <Container>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Modal open={openModal} onClose={props.onClose}>
              <Box sx={style}>
                <DialogTitle
                  id="customized-dialog-title"
                  sx={{ textAlign: 'center', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <Typography variant="h6" component="h2">
                    Invoice
                  </Typography>
                  <Button onClick={props.onClose} variant="outlined">
                    Cancel
                  </Button>
                </DialogTitle>

                {/* <Button onClick={handleCloseModal}>Cancel</Button> */}
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <PDFViewer width={'100%'} height="550" className="app">
                    <Document>
                      <Page>
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            paddingHorizontal: 10,
                            justifyContent: 'space-between',
                          }}
                        >
                          {/* Company Information Side */}
                          {/*
                          <View
                            style={{
                              width: '30%',
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              paddingTop: 1,
                            }}
                          >
                            <View
                              style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                paddingTop: 50,
                                paddingBottom: 50,
                                paddingLeft: 10,
                                backgroundColor: '#1155cc', // Blue background color
                                color: 'white', // Text color
                                justifyContent: 'center',
                              }}
                            >
                              <Image style={styles.logo} src={logo} />
                              <Text style={{ fontFamily: 'KadwaBold', padding: 10 }}>AioDock Digital</Text>
                            </View>

                            <View
                              style={{
                                width: '100%',
                                display: 'flex',
                                height: '100%',
                                flexDirection: 'column',
                                alignItems: 'center',
                                paddingTop: 50,
                                // paddingBottom: 50,
                                paddingLeft: 10,
                                backgroundColor: '#cfe2f3',
                                // justifyContent: 'center',
                                color: 'grey',
                                fontSize: 9,
                                fontFamily: 'KadwaRegular',
                              }}
                            >
                              <View>
                                <Image style={styles.logo} src={logo} />
                              </View>
                              <View>
                                <View style={{ paddingTop: 35, alignItems: 'flex-start' }}>
                                  <Text>AioDock Digital</Text>
                                </View>
                                <View style={{ paddingTop: 10, alignItems: 'flex-start' }}>
                                  <Text>+92 317 5766380</Text>
                                </View>
                                <View style={{ paddingTop: 10, alignItems: 'flex-start' }}>
                                  <Text>rehan@aiodock.com</Text>
                                </View>
                                <View style={{ paddingTop: 10, alignItems: 'flex-start' }}>
                                  <Text>https://aiodockdigital.com</Text>
                                </View>
                                <View style={{ paddingTop: 10, alignItems: 'flex-start' }}>
                                  <Text>
                                    Office No. 318, Hamdan{'\n'} Heights, Islamabad Expressway,{'\n'} Islamabad.
                                  </Text>
                                </View>
                              </View>
                            </View>
                            
                          </View>
                            */}

                          {/* Client Information Side */}
                          <View
                            style={{
                              width: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                            fixed
                          >
                            <View
                              style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                              }}
                            >
                              <View
                                style={{
                                  width: '50%',
                                  justifyContent: 'flex-start',
                                }}
                              >
                                <Image style={styles.logo} src={logo} />
                                <Text style={{ fontFamily: 'KadwaBold', padding: 10 }}>AioDock Digital</Text>
                              </View>
                              <View
                                style={{
                                  width: '50%',
                                  alignItems: 'center',
                                  justifyContent: 'flex-end',
                                }}
                              >
                                <Text
                                  style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginRight: 10,
                                    fontFamily: 'KadwaRegular',
                                    fontSize: 12,
                                  }}
                                >
                                  DATE: <Text style={{}}>{new Date(details.date).toLocaleDateString()}</Text>
                                  {'\n'}
                                  <Text style={{}}>INVOICE NO. #</Text> <Text style={{}}>{details.invoiceId}</Text>
                                  {'\n'}
                                  <Text style={{}}>PO NO. #</Text> <Text style={{}}>{details.PON}</Text>
                                  {'\n'}
                                </Text>
                              </View>
                            </View>

                            {/* Bill To Heading  */}
                            <View
                              style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                borderBottom: 1,
                                borderColor: 'grey',
                              }}
                            >
                              <View>
                                <Text style={{ fontFamily: 'KadwaBold', paddingLeft: 10, fontSize: 12 }}>BILL TO</Text>
                              </View>
                            </View>

                            {/* Bill To Details  */}
                            <View
                              style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start',

                                // paddingTop: 20,
                                fontFamily: 'KadwaRegular',
                                paddingLeft: 10,
                                fontSize: 9,
                              }}
                            >
                              <View>
                                <Text style={{}}>Name: {details.clientName}</Text>
                                {/* <Text style={{}}>Phone: +92 317 5766380</Text>
                                <Text style={{}}>Email: rehan@aiodock.com</Text> */}
                                <Text style={{}}>Company: {details.clientCompany}</Text>
                                <Text style={{}}>Address: {details.clientAddress}</Text>
                              </View>
                            </View>

                            {/* Description */}
                            {/* Bill To Heading  */}
                            <View
                              style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                borderBottom: 1,
                                borderColor: 'grey',
                              }}
                            >
                              <View>
                                <Text style={{ fontFamily: 'KadwaBold', paddingLeft: 10, fontSize: 12 }}>DETAILS</Text>
                              </View>
                            </View>
                            <View
                              style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                // alignItems: 'center',
                                // justifyContent: 'flex-start',
                                // paddingTop: 20,
                                fontFamily: 'KadwaRegular',
                                // paddingLeft: 10,
                                fontSize: 12,
                              }}
                            >
                              <View style={styles.table}>
                                <View style={styles.tableRowB}>
                                  <Text style={styles.tableHeadingA}>Description</Text>
                                  <Text style={styles.tableHeadingB}>Rate {'\n'} Per Sq.</Text>
                                  <Text style={styles.tableHeadingC}>Quantity</Text>
                                  <Text style={styles.tableHeadingD}>
                                    Unit
                                    {'\n'}
                                    Price
                                  </Text>
                                  <Text style={styles.tableHeadingE}>Amount</Text>
                                  <Text style={styles.tableHeadingF}>Discount</Text>
                                  <Text style={styles.tableHeadingG}>Total</Text>
                                </View>

                                <View style={styles.tableRowA}>
                                  <Text style={styles.serviceDescription}>
                                    <Text style={[styles.module, { marginLeft: 10 }]}>
                                      {details.module} - {details.category} SMD Screen
                                    </Text>
                                    <br />
                                    {'\n'}
                                    <Text style={styles.cardsInfoData}>
                                      Full Color with Complete Installation and {'\n'} Fabrication.
                                    </Text>
                                    {'\n'}
                                    <Text style={styles.cardsInfo}>Sending Cards:</Text>
                                    <Text style={styles.cardsInfoData}> {details.sendingCardInfo}</Text>
                                    {'\n'}
                                    <Text style={styles.cardsInfo}>Receiving Cards:</Text>
                                    <Text style={styles.cardsInfoData}> {details.receivingCardInfo}</Text>
                                    {'\n'}
                                    {details.cabinet === 'yes' && (
                                      <Text>
                                        <Text style={styles.cardsInfo}>Cabinet Information:</Text>
                                        <Text style={styles.cardsInfoData}> {details.cabinetInfo}</Text>
                                      </Text>
                                    )}
                                    {'\n'}
                                    <Text style={styles.serviceAmount}>Size:</Text>
                                    <Text style={styles.module}>{details.screenWidth} Ft (W)</Text> by{' '}
                                    <Text style={styles.module}>{details.screenHeight} Ft (H)</Text>
                                  </Text>
                                  <Text
                                    style={{ textAlign: 'left', fontFamily: 'KadwaBold', width: '10%', fontSize: 9 }}
                                  >
                                    {numberWithCommas(details.rate)}
                                  </Text>
                                  <Text
                                    style={{ textAlign: 'center', fontFamily: 'KadwaBold', width: '8%', fontSize: 9 }}
                                  >
                                    {details.noOfScreen}
                                  </Text>
                                  <Text
                                    style={{ textAlign: 'center', fontFamily: 'KadwaBold', width: '10%', fontSize: 9 }}
                                  >
                                    {numberWithCommas(
                                      parseInt(details.screenWidth * details.screenHeight * details.rate, 10)
                                    )}
                                  </Text>

                                  <Text
                                    style={{ textAlign: 'center', fontFamily: 'KadwaBold', width: '15%', fontSize: 9 }}
                                  >
                                    {numberWithCommas(
                                      (
                                        parseInt(details.screenWidth * details.screenHeight * details.rate, 10) *
                                        details.noOfScreen
                                      ).toFixed(0)
                                    )}
                                  </Text>
                                  <Text
                                    style={{ textAlign: 'center', fontFamily: 'KadwaBold', width: '10%', fontSize: 9 }}
                                  >
                                    {numberWithCommas(
                                      parseInt(
                                        details.discount *
                                          details.screenWidth *
                                          details.screenHeight *
                                          details.noOfScreen,
                                        10
                                      )
                                    )}
                                  </Text>
                                  <Text
                                    style={{ textAlign: 'center', fontFamily: 'KadwaBold', width: '17%', fontSize: 9 }}
                                  >
                                    {numberWithCommas(
                                      parseInt(
                                        details.screenWidth *
                                          details.screenHeight *
                                          (details.rate - parseInt(details.discount, 10)) *
                                          details.noOfScreen,
                                        10
                                      )
                                    )}{' '}
                                  </Text>
                                </View>
                              </View>
                            </View>

                            {/* Remarks */}
                            <View
                              style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                fontFamily: 'KadwaRegular',
                                paddingLeft: 10,
                                fontSize: 12,
                              }}
                            >
                              {/* Remarks */}
                              <View
                                style={{
                                  width: '40%',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'flex-start',
                                  paddingLeft: 10,
                                }}
                              >
                                <Text style={{ fontSize: 12, fontFamily: 'KadwaBold' }}>Remarks:</Text>
                                {'\n'}
                                <Text style={{ fontSize: 12, fontFamily: 'KadwaRegular' }}>{details.remarks}</Text>
                              </View>

                              {/* Total Section */}
                              <View
                                style={{
                                  width: '60%',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'flex-start',
                                  fontFamily: 'KadwaRegular',
                                  paddingLeft: 10,
                                  fontSize: 12,
                                }}
                              >
                                {/* SubTotal */}
                                <View
                                  style={{
                                    width: '100%',
                                    justifyContent: 'flex-start',
                                    fontFamily: 'KadwaRegular',

                                    fontSize: 12,
                                    display: 'flex',
                                    flexDirection: 'row',
                                  }}
                                >
                                  <View
                                    style={{
                                      width: '50%',
                                      justifyContent: 'flex-start',
                                      fontFamily: 'KadwaRegular',
                                      paddingLeft: 10,
                                      fontSize: 12,
                                    }}
                                  >
                                    <Text>Sub Total</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: '50%',
                                      alignItems: 'center',
                                      justifyContent: 'flex-start',
                                      fontFamily: 'KadwaRegular',
                                      paddingLeft: 10,
                                      fontSize: 12,
                                      borderBottom: 1,
                                      borderColor: 'grey',
                                    }}
                                  >
                                    <Text>
                                      {numberWithCommas(
                                        parseInt(
                                          details.screenWidth *
                                            details.screenHeight *
                                            (details.rate - parseInt(details.discount, 10)) *
                                            details.noOfScreen,
                                          10
                                        ).toFixed(0)
                                      )}{' '}
                                      /- Rs
                                    </Text>
                                  </View>
                                </View>
                                {/* GST Percentage */}
                                <View
                                  style={{
                                    width: '100%',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    fontFamily: 'KadwaRegular',

                                    fontSize: 12,
                                    display: 'flex',
                                    flexDirection: 'row',
                                  }}
                                >
                                  <View
                                    style={{
                                      width: '50%',
                                      justifyContent: 'flex-start',
                                      fontFamily: 'KadwaRegular',
                                      paddingLeft: 10,
                                      fontSize: 12,
                                    }}
                                  >
                                    <Text>GST %</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: '50%',
                                      alignItems: 'center',
                                      justifyContent: 'flex-start',
                                      fontFamily: 'KadwaRegular',
                                      paddingLeft: 10,
                                      fontSize: 12,
                                      borderBottom: 1,
                                      borderColor: 'grey',
                                    }}
                                  >
                                    <Text>{details.gst === 'yes' ? '18 %' : '0.00 %'}</Text>
                                  </View>
                                </View>

                                {/* GST Total Amount */}

                                <View
                                  style={{
                                    width: '100%',
                                    justifyContent: 'flex-start',
                                    fontFamily: 'KadwaRegular',

                                    fontSize: 12,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    borderColor: 'black',
                                    borderBottom: 1,
                                  }}
                                >
                                  <View
                                    style={{
                                      width: '50%',
                                      justifyContent: 'flex-start',
                                      fontFamily: 'KadwaRegular',
                                      paddingLeft: 10,
                                      fontSize: 12,
                                    }}
                                  >
                                    <Text>GST Amount</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: '50%',
                                      alignItems: 'center',
                                      justifyContent: 'flex-start',
                                      fontFamily: 'KadwaRegular',
                                      paddingLeft: 10,
                                      fontSize: 12,
                                    }}
                                  >
                                    <Text>
                                      {details.gst === 'yes'
                                        ? numberWithCommas(
                                            (
                                              ((details.noOfScreen *
                                                (details.screenWidth * details.screenHeight * details.rate)) /
                                                100) *
                                              18
                                            ).toFixed(0)
                                          )
                                        : '0.00'}{' '}
                                      /- Rs
                                    </Text>
                                  </View>
                                </View>

                                {/* Sub Total */}

                                <View
                                  style={{
                                    width: '100%',
                                    justifyContent: 'flex-start',
                                    fontFamily: 'KadwaRegular',
                                    paddingLeft: 10,
                                    fontSize: 12,
                                    display: 'flex',
                                    flexDirection: 'row',
                                  }}
                                >
                                  <View
                                    style={{
                                      width: '50%',
                                      alignItems: 'left',
                                      justifyContent: 'flex-start',
                                      fontFamily: 'KadwaBold',
                                      paddingLeft: 10,
                                      fontSize: 12,
                                    }}
                                  >
                                    <Text>Final Price</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: '50%',
                                      alignItems: 'center',
                                      justifyContent: 'flex-start',
                                      fontFamily: 'KadwaRegular',
                                      paddingLeft: 10,
                                      borderBottom: 1,
                                      borderColor: 'black',
                                      backgroundColor: '#cfe2f3',
                                    }}
                                  >
                                    <Text>
                                      {details.gst === 'yes'
                                        ? numberWithCommas(
                                            parseInt(
                                              parseInt(
                                                details.screenWidth *
                                                  details.screenHeight *
                                                  (details.rate - parseInt(details.discount, 10)) *
                                                  details.noOfScreen,
                                                10
                                              ) +
                                                ((parseInt(
                                                  details.screenWidth * details.screenHeight * details.rate,
                                                  10
                                                ) *
                                                  details.noOfScreen) /
                                                  100) *
                                                  18,
                                              10
                                            )
                                          )
                                        : numberWithCommas(
                                            parseInt(
                                              details.screenWidth *
                                                details.screenHeight *
                                                (details.rate - parseInt(details.discount, 10)) *
                                                details.noOfScreen,
                                              10
                                            ).toFixed(0)
                                          )}{' '}
                                      /- Rs
                                    </Text>
                                  </View>
                                </View>
                              </View>
                            </View>

                            {/* Signatures */}
                            <View
                              style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                // paddingTop: 20,
                                fontFamily: 'KadwaRegular',
                                paddingLeft: 10,
                                fontSize: 12,
                                marginTop: 130,
                                marginBottom: 40,
                              }}
                            >
                              <View
                                style={{
                                  width: '40%',
                                  borderTop: 1,
                                  paddingRight: 20,
                                  borderColor: 'black',
                                }}
                              >
                                <Text style={{ textAlign: 'center' }}>Company Signature</Text>
                              </View>
                              <View
                                style={{
                                  width: '20%',
                                }}
                              />
                              <View
                                style={{
                                  width: '40%',
                                  borderTop: 1,
                                  marginRight: 20,
                                  borderColor: 'black',
                                }}
                              >
                                <Text style={{ textAlign: 'center' }}>Client Signature</Text>
                              </View>
                            </View>
                            {/* Address */}
                            <View
                              style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                // alignItems: 'center',
                                justifyContent: 'flex-start', 
                                // paddingTop: 20,
                                fontFamily: 'KadwaRegular',
                                // paddingLeft: 10,
                                fontSize: 12,
                                marginTop: 140,
                              }}
                            >
                              <View style={styles.footer}>
                                <Text>
                                  Office No. 318, Hamdan Heights, Islamabad Expressway, Islamabad.
                                  {'\n'}
                                  info@aiodock.com &bull; +92 318 7745768
                                </Text>
                              </View>
                            </View>
                          </View>
                        </View>
                      </Page>
                    </Document>
                  </PDFViewer>
                </Typography>
              </Box>
            </Modal>
          </Stack>
        </LocalizationProvider>
      </Container>
    </>
  );
}
