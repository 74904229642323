import * as React from 'react';
import { useState, useEffect } from 'react';
// date and time
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// @mui

import {
  Box,
  Stack,
  Button,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Container,
  Typography,
  TextField,
  Modal,
  Grid,
  DialogTitle,
} from '@mui/material';
// components
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image, Font } from '@react-pdf/renderer';

import logo from '../Images/logo.png';
import watermark from '../Images/watermark.png';
import embassy from '../Images/lg.png';
import billboard from '../Images/BILLBOARD.png';
import unilumin from '../Images/unilumin.png';

Font.register({ src: 'https://fonts.gstatic.com/s/kadwa/v2/rnCm-x5V0g7ipiTAT8M.ttf', family: 'KadwaRegular' });
Font.register({ src: 'https://fonts.gstatic.com/s/kadwa/v2/rnCr-x5V0g7ipix7atM5kn0.ttf', family: 'KadwaBold' });
Font.register({
  src: 'https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459Wlhzg.ttf',
  family: 'MontserratRegular',
});

export default function PrintPDFQoutation(props) {
  const [details, setDetails] = useState(props.data);
  // date n timne
  const [value, setValue] = React.useState(dayjs('2014-08-18T21:11:54'));
  const [leaveFrom, setLeaveFrom] = React.useState(dayjs('2014-08-18T21:11:54'));
  const [leaveTo, setLeaveTo] = React.useState(dayjs('2014-08-18T21:11:54'));

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  // ---------------------Modal-------------------------------
  const [openModal, setOpenModal] = useState(true);
  //   const [open, setOpen] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: '95%',
  };
  const [openEditModal, setOpenEditModal] = useState(true);
  const [closeEditModal, setCloseEditModal] = useState(false);

  const styles = StyleSheet.create({
    watermark: {
      position: 'absolute',
      top: '50%', // Adjust the vertical positioning
      left: '50%', // Adjust the horizontal positioning
      transform: 'translate(-50%, -50%)', // Center the watermark
    },
    watermarkImage: {
      width: '100px', // Adjust the image width
      height: '100px', // Adjust the image height
      opacity: 0.2, // Adjust the image opacity
    },
    watermarkText: {
      fontSize: 20, // Adjust the font size
      color: 'rgba(0, 0, 0, 1)', // Adjust the text color
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    couple: {
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'center',
    },
    huidu: {
      width: 100,
      height: 30,
      marginLeft: 40,
      // marginTop: 12.5,
      // marginBottom: 12.5,
    },
    No1Brand: {
      width: 80,
      height: 30,
    },
    genergy: {
      width: 50,
      height: 30,
      marginLeft: 65,
      // marginTop: 5,
      // marginBottom: 5,
    },
    unilumin: {
      width: 100,
      height: 30,
      marginLeft: 15,
      // marginTop: 45,
      // marginBottom: 5,
    },
    fiba: {
      width: 80,
      height: 30,
    },
    logo: {
      width: 80,
      height: 50,
    },
    clientsImages: {
      marginTop: 5,
      width: 100,
      height: 70,
    },
    auth: {
      width: 120,
      height: 50,
      position: 'absolute',
      marginTop: 100,
      marginLeft: 30,
    },
    header: {
      // borderTopWidth: 4,
      // borderTopColor: '#0084B4',
      color: '#898989',
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 20,
      paddingBottom: 10,
      display: 'flex',
      flexDirection: 'row',
    },
    headerLeft: {
      textAlign: 'left',
      flex: 1,
      alignSelf: 'center',
      fontSize: 32,
      fontWeight: 900,
      lineHeight: 1,
      color: '#0084B4',
      fontFamily: 'KadwaBold',
    },
    headerRight: {
      textAlign: 'right',
      fontSize: 12,
      // flex: 1,
      fontFamily: 'KadwaBold',
    },

    ribbonBlueTerms: {
      backgroundColor: '#0084B4',
      display: 'flex',
      flexDirection: 'row',
      // padding: 6,
      marginTop: 20,
      textAlign: 'center',
      color: '#FFF',
    },
    ribbonBlue: {
      backgroundColor: '#0084B4',
      display: 'flex',
      flexDirection: 'row',
      // padding: 6,
      // marginBottom: 20,
      textAlign: 'center',
      color: '#FFF',
    },
    blueRibbonValues: {
      // backgroundColor: '#0084B4',
      display: 'flex',
      flexDirection: 'row',
      // padding: 6,
      // marginBottom: 15,
      textAlign: 'center',
      color: '#FFF',
    },
    blueRibbonValues1: {
      // paddingTop:5,
      // backgroundColor: '#0084B4',
      display: 'flex',
      flexDirection: 'row',
      // padding: 6,
      // marginBottom: 15,
      // textAlign: 'center',
      color: '#FFF',
      paddingLeft: 10,
      borderBottom: '1px solid #0084B4',
    },
    ribbonGrey: {
      backgroundColor: '#EDEDED',
      display: 'flex',
      flexDirection: 'row',
      padding: 10,
      // marginBottom: 10,
      textAlign: 'center',
      color: '#0084B4',
    },
    ribbonBox: {
      width: '33.333333%',
    },
    ntnBox: {
      width: '25%',
    },
    TermsConditionBox: {
      width: '100%',
    },
    descriptionBox1: {
      width: '50%',
      borderRight: '1px solid #0084B4',
    },

    descriptionBox2: {
      width: '20%',
      borderRight: '1px solid #0084B4',
    },
    pricing: {
      display: 'flex',
      flexDirection: 'row',
      fontSize: 10,
      borderBottom: '1px solid #0084B4',
    },
    pricingBox1: {
      width: '73.75%',
      borderRight: '1px solid #0084B4',
      fontFamily: 'KadwaRegular',
      textAlign: 'right',
    },
    pricingBox2: {
      width: '26.25%',
      fontFamily: 'KadwaBold',
      color: 'black',
      textAlign: 'center',
    },
    pricingBox3: {
      width: '73.40%',
      borderRight: '1px solid #0084B4',
      fontFamily: 'KadwaRegular',
      textAlign: 'right',
    },
    pricingBox4: {
      width: '26.60%',
      fontFamily: 'KadwaBold',
      color: 'black',
      textAlign: 'center',
    },
    client: {
      fontSize: 10,
      fontFamily: 'KadwaRegular',
      color: 'black',
    },
    ribbonLabel: {
      fontSize: 10,
      fontFamily: 'KadwaBold',
    },
    ribbonLabel2: {
      fontSize: 10,
      fontFamily: 'KadwaBold',
      color: 'black',
    },
    quotation: {
      fontSize: 12,
      fontFamily: 'KadwaBold',
      color: '#0084B4',
    },
    ribbonValue: {
      fontSize: 16,
      fontFamily: 'KadwaBold',
      color: 'red',
    },
    ribbonValue1: {
      fontSize: 12,
      fontFamily: 'KadwaBold',
      color: 'black',
    },

    table: {
      paddingHorizontal: 20,
      display: 'flex',
      marginBottom: 10,
    },
    tableRowA: {
      backgroundColor: '#EDEDED',
      display: 'flex',
      flexDirection: 'row',
      padding: 10,
    },
    tableRowB: {
      padding: 10,
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'row',
    },
    serviceName: {
      fontSize: 10,
      width: '25%',
      fontFamily: 'KadwaBold',
    },
    serviceDescription: {
      fontSize: 8,
      width: '50%',
      // fontFamily: 'MontserratRegular',
    },
    differ: {
      fontSize: 10,
      fontFamily: 'KadwaRegular',
      Left: '20',
    },
    module: {
      fontSize: 12,
      width: '40%',
      color: 'red',
      fontFamily: 'KadwaBold',
    },
    cardsInfo: {
      fontSize: 9,
      width: '10%',
      // textAlign: 'left',
      fontFamily: 'KadwaBold',
      paddingLeft: 10,
    },
    uniluminInfo: {
      fontSize: 9,
      width: '10%',
      // textAlign: 'left',
      fontFamily: 'KadwaBold',
      paddingLeft: 20,
    },
    cardsInfoData: {
      fontSize: 9,
      // width: '10%',
      // textAlign: 'left',
      fontFamily: 'KadwaRegular',
      paddingLeft: 10,
    },
    fullColor: {
      fontSize: 7,
      // width: '10%',
      textAlign: 'left',
      fontFamily: 'KadwaBold',
    },
    serviceAmount: {
      fontSize: 9,
      // width: '15%',
      // textAlign: 'center',
      fontFamily: 'KadwaBold',
    },
    serviceAmountLast: {
      fontSize: 10,
      width: '15%',
      textAlign: 'right',
      fontFamily: 'KadwaBold',
    },
    tableHeadingA: {
      width: '40%',
      fontSize: 10,
      textAlign: 'left',
      color: '#0084B4',
      fontFamily: 'KadwaBold',
    },
    tableHeadingB: {
      width: '15%',
      fontSize: 10,
      textAlign: 'center',
      color: '#0084B4',
      fontFamily: 'KadwaBold',
    },
    tableHeadingC: {
      width: '15%',
      textAlign: 'center',
      fontSize: 10,
      color: '#0084B4',
      fontFamily: 'KadwaBold',
    },
    tableHeadingLast: {
      width: '15%',
      textAlign: 'right',
      fontSize: 10,
      color: '#0084B4',
      fontFamily: 'KadwaBold',
    },
    summary: {
      backgroundColor: '#EDEDED',
      color: '#FFF',
      padding: 5,
      paddingLeft: 10,
      paddingRight: 10,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'left',
    },
    summaryMeta: {
      width: '40%',
      fontSize: 10,
      color: '#0084B4',
      fontFamily: 'KadwaBold',
      paddingLeft: 10,
    },
    summaryAmount: {
      fontSize: 15,
      width: '60%',
      color: 'black',
      textAlign: 'right',
      fontFamily: 'KadwaBold',
    },

    howToPay: {
      paddingHorizontal: 20,
      textAlign: 'center',
      fontSize: 15,
      color: '#0084B4',
      fontFamily: 'KadwaBold',
    },
    ltd: {
      textAlign: 'left',
      fontSize: 14,
      color: '#0084B4',
      fontFamily: 'KadwaBold',
    },
    TermsCondition: {
      paddingHorizontal: 20,
      textAlign: 'center',
      fontSize: 12,
      color: '#fff',
      fontFamily: 'KadwaBold',
    },
    TermsConditionHeadings: {
      paddingLeft: 20,
      textAlign: 'left',
      fontSize: 9,
      color: 'black',
      fontFamily: 'Regular',
    },
    TermsConditionLine: {
      paddingLeft: 20,
      textAlign: 'left',
      fontSize: 9,
      color: 'black',
      fontFamily: 'KadwaRegular',
    },
    TermsConditionLineHeading: {
      // paddingLeft: 20,
      // textAlign: 'left',
      fontSize: 10,
      color: '#0084B4',
      fontFamily: 'KadwaBold',
    },

    footer: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      padding: 20,
      borderTopColor: '#EDEDED',
      borderTopWidth: 1,
      textAlign: 'center',
      fontSize: 10,
      fontFamily: 'KadwaRegular',
    },
  });
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };
  const dueDate = new Date(details.date);
  dueDate.setDate(dueDate.getDate() + 5);

  return (
    <>
      <Container>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Modal open={openModal} onClose={props.onClose}>
              <Box sx={style}>
                <DialogTitle
                  id="customized-dialog-title"
                  sx={{ textAlign: 'center', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <Typography variant="h6" component="h2">
                    Quotation
                  </Typography>
                  <Button onClick={props.onClose} variant="outlined">
                    Cancel
                  </Button>
                </DialogTitle>

                {/* <Button onClick={handleCloseModal}>Cancel</Button> */}
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <PDFViewer width={'100%'} height="550" className="app">
                    <Document>
                      <Page style={styles.body}>
                        <View style={styles.header}>
                          {/* View # 01 for Logo and Client Information */}
                          <View style={styles.headerLeft}>
                            <Image style={styles.logo} src={logo} />
                            <Text style={styles.ltd}>AioDock Digital</Text>
                          </View>
                          <View style={styles.headerRight}>YYY
                            <Text style={styles.quotation}>Quotation to</Text>
                            <Text style={styles.client}>
                              {details.surName} {details.clientName}
                            </Text>
                            {/* <Text style={styles.client}>
                              {details.gs} {details.clientName}
                            </Text> */}
                            {details.gst === 'yes' ? <Text style={styles.client}>NTN # {details.ntn}</Text> : null}
                            {/* <Text style={styles.client}>STN # {details.stn}</Text> */}
                            <Text style={styles.client}>{details.clientAddress}</Text>
                          </View>
                        </View>

                        {/* ########################################################################## */}

                        {/* View # 02 Blue Ribbon for Date, Quotation and Module */}

                        {details.gst === 'yes' ? (
                          <>
                            <View style={styles.ribbonBlue}>
                              <View style={styles.ntnBox}>
                                <Text style={styles.ribbonLabel}>Date</Text>
                              </View>
                              <View style={styles.ntnBox}>
                                <Text style={styles.ribbonLabel}>NTN No.</Text>
                              </View>
                              <View style={styles.ntnBox}>
                                <Text style={styles.ribbonLabel}>Quotation No #</Text>
                              </View>
                              <View style={styles.ntnBox}>
                                <Text style={styles.ribbonLabel}>Module</Text>
                              </View>
                            </View>
                            <View style={styles.blueRibbonValues}>
                              <View style={styles.ntnBox}>
                                <Text style={styles.ribbonValue1}>{new Date(details.date).toLocaleDateString()}</Text>
                              </View>
                              <View style={styles.ntnBox}>
                                <Text style={styles.ribbonValue1}>A042167-2</Text>
                              </View>
                              <View style={styles.ntnBox}>
                                <Text style={styles.ribbonValue1}>{details.quotationId}</Text>
                              </View>
                              <View style={styles.ntnBox}>
                                <Text style={styles.ribbonValue1}>
                                  {details.module} {details.category}
                                </Text>
                              </View>
                            </View>
                          </>
                        ) : (
                          <>
                            <View style={styles.ribbonBlue}>
                              <View style={styles.ribbonBox}>
                                <Text style={styles.ribbonLabel}>Date</Text>
                              </View>
                              <View style={styles.ribbonBox}>
                                <Text style={styles.ribbonLabel}>Quotation No #</Text>
                              </View>
                              <View style={styles.ribbonBox}>
                                <Text style={styles.ribbonLabel}>Module</Text>
                              </View>
                            </View>
                            <View style={styles.blueRibbonValues}>
                              <View style={styles.ribbonBox}>
                                <Text style={styles.ribbonValue1}>{new Date(details.date).toLocaleDateString()}</Text>
                              </View>
                              <View style={styles.ribbonBox}>
                                <Text style={styles.ribbonValue1}>{details.quotationId}</Text>
                              </View>
                              <View style={styles.ribbonBox}>
                                <Text style={styles.ribbonValue1}>
                                  {details.module} {details.category}
                                </Text>
                              </View>
                            </View>
                          </>
                        )}

                        {/* ########################################################################## */}
                        {/* View # 03 Blue Ribbon for Description etc */}

                        <View style={styles.ribbonBlue}>
                          <View style={styles.descriptionBox1}>
                            <Text style={styles.ribbonLabel}>Description</Text>
                          </View>
                          <View style={styles.descriptionBox2}>
                            <Text style={styles.ribbonLabel}>Size</Text>
                          </View>
                          <View style={styles.descriptionBox2}>
                            <Text style={styles.ribbonLabel}>Quantity</Text>
                          </View>
                          <View style={styles.descriptionBox2}>
                            <Text style={styles.ribbonLabel}>Price Sq.Ft</Text>
                          </View>
                          <View style={styles.descriptionBox2}>
                            <Text style={styles.ribbonLabel}>Unit Price</Text>
                          </View>
                          <View style={styles.descriptionBox2}>
                            <Text style={styles.ribbonLabel}>Total Price</Text>
                          </View>
                        </View>

                        <View style={styles.blueRibbonValues1}>
                          <View style={styles.descriptionBox1}>
                            <Text style={styles.ribbonLabel2}>
                              <Text style={[styles.serviceDescription, { paddingTop: 10 }]}>
                                <Text style={styles.module}>
                                  {details.module} - {details.category} SMD Screen
                                  {'\n'}
                                  UNILUMIN
                                </Text>
                                {/* <Text style={styles.cardsInfoData}>
                                  <Image style={styles.unilumin} src={unilumin} />
                                </Text> */}
                                {/* <Text style={styles.uniluminInfo}>Module Brand:</Text>{' '} */}
                                {'\n'}
                                {details.module === 'P 1.5' ||
                                  (details.module === 'P1.5' && (
                                    <Text style={styles.serviceDescription}>
                                      <Text style={styles.cardsInfo}>LED Type:</Text>{' '}
                                      <Text style={styles.cardsInfoData}>SMD1212</Text>
                                      {'\n'}
                                      <Text style={styles.cardsInfo}>Pixel Pitch:</Text>{' '}
                                      <Text style={styles.cardsInfoData}>1.538mm</Text>
                                      {'\n'}
                                      <Text style={styles.cardsInfo}>Video Support:</Text>{' '}
                                      <Text style={styles.cardsInfoData}>2K HD, 4K UHD</Text>
                                      {'\n'}
                                      <Text style={styles.cardsInfo}>Contrast Ratio:</Text>{' '}
                                      <Text style={styles.cardsInfoData}>3000 : 1</Text>
                                      {'\n'}
                                      <Text style={styles.cardsInfo}>Refresh Rate:</Text>{' '}
                                      <Text style={styles.cardsInfoData}>3840Hz</Text>
                                      {'\n'}
                                      <Text style={styles.cardsInfo}>Module Resolution:</Text>{' '}
                                      <Text style={styles.cardsInfoData}>208*104=21632 Pixels</Text>
                                      {'\n'}
                                      <Text style={styles.cardsInfo}>Beam Angle (Hor°/Ver°):</Text>{' '}
                                      <Text style={styles.cardsInfoData}>140°/140°</Text>
                                      {'\n'}
                                    </Text>
                                  ))}
                                {/* P 1.8 Specifications */}
                                {details.module === 'P 1.8' ||
                                  (details.module === 'P1.8' && (
                                    <Text style={styles.serviceDescription}>
                                      <Text style={styles.cardsInfo}>LED Type:</Text>{' '}
                                      <Text style={styles.cardsInfoData}>SMD1212</Text>
                                      {'\n'}
                                      <Text style={styles.cardsInfo}>Pixel Pitch:</Text>{' '}
                                      <Text style={styles.cardsInfoData}>1.839mm</Text>
                                      {'\n'}
                                      <Text style={styles.cardsInfo}>Video Support:</Text>{' '}
                                      <Text style={styles.cardsInfoData}>2K HD, 4K UHD</Text>
                                      {'\n'}
                                      <Text style={styles.cardsInfo}>Contrast Ratio:</Text>{' '}
                                      <Text style={styles.cardsInfoData}>3000 : 1</Text>
                                      {'\n'}
                                      <Text style={styles.cardsInfo}>Refresh Rate:</Text>{' '}
                                      <Text style={styles.cardsInfoData}>3840Hz</Text>
                                      {'\n'}
                                      <Text style={styles.cardsInfo}>Module Resolution:</Text>{' '}
                                      <Text style={styles.cardsInfoData}>174*87=15138 Pixels</Text>
                                      {'\n'}
                                      <Text style={styles.cardsInfo}>Beam Angle (Hor°/Ver°):</Text>{' '}
                                      <Text style={styles.cardsInfoData}>140°/140°</Text>
                                      {'\n'}
                                    </Text>
                                  ))}
                                {/* P 2.5 Specifications */}
                                {details.module === 'P 2.5' ||
                                  (details.module === 'P2.5' && (
                                    <Text style={styles.serviceDescription}>
                                      <Text style={styles.cardsInfo}>LED Type:</Text>{' '}
                                      <Text style={styles.cardsInfoData}>SMD2121</Text>
                                      {'\n'}
                                      <Text style={styles.cardsInfo}>Pixel Pitch:</Text>{' '}
                                      <Text style={styles.cardsInfoData}>2.55mm</Text>
                                      {'\n'}
                                      <Text style={styles.cardsInfo}>Video Support:</Text>{' '}
                                      <Text style={styles.cardsInfoData}>2K HD, 4K UHD</Text>
                                      {'\n'}
                                      <Text style={styles.cardsInfo}>Contrast Ratio:</Text>{' '}
                                      <Text style={styles.cardsInfoData}>5000 : 1</Text>
                                      {'\n'}
                                      <Text style={styles.cardsInfo}>Refresh Rate:</Text>{' '}
                                      <Text style={styles.cardsInfoData}>1920Hz</Text>
                                      {'\n'}
                                      <Text style={styles.cardsInfo}>Module Resolution:</Text>{' '}
                                      <Text style={styles.cardsInfoData}>128*64=8192 Pixels</Text>
                                      {'\n'}
                                      <Text style={styles.cardsInfo}>Beam Angle (Hor°/Ver°):</Text>{' '}
                                      <Text style={styles.cardsInfoData}>140°/140°</Text>
                                      {'\n'}
                                    </Text>
                                  ))}
                                {/* P 10 Specifications */}
                                {details.module === 'P 10' ||
                                  (details.module === 'P10' && (
                                    <Text style={styles.serviceDescription}>
                                      <Text style={styles.cardsInfo}>LED Type:</Text>{' '}
                                      <Text style={styles.cardsInfoData}>SMD3535</Text>
                                      {'\n'}
                                      <Text style={styles.cardsInfo}>Pixel Pitch:</Text>{' '}
                                      <Text style={styles.cardsInfoData}>10mm</Text>
                                      {'\n'}
                                      <Text style={styles.cardsInfo}>Video Support:</Text>{' '}
                                      <Text style={styles.cardsInfoData}>2K HD, 4K UHD</Text>
                                      {'\n'}
                                      <Text style={styles.cardsInfo}>Contrast Ratio:</Text>{' '}
                                      <Text style={styles.cardsInfoData}>3000 : 1</Text>
                                      {'\n'}
                                      <Text style={styles.cardsInfo}>Refresh Rate:</Text>{' '}
                                      <Text style={styles.cardsInfoData}>≥1920Hz</Text>
                                      {'\n'}
                                      <Text style={styles.cardsInfo}>Module Resolution:</Text>{' '}
                                      <Text style={styles.cardsInfoData}>32*16=512 Pixels</Text>
                                      {'\n'}
                                      <Text style={styles.cardsInfo}>Beam Angle (Hor°/Ver°):</Text>{' '}
                                      <Text style={styles.cardsInfoData}>140°/120°</Text>
                                      {'\n'}
                                    </Text>
                                  ))}
                                {/* P 4 Specifications */}
                                {details.module === 'P 4' ||
                                  (details.module === 'P4' && (
                                    <Text>
                                      <Text style={styles.cardsInfo}>LED Type:</Text>{' '}
                                      <Text style={styles.cardsInfoData}>SMD2121</Text>
                                      {'\n'}
                                      <Text style={styles.cardsInfo}>Pixel Pitch:</Text>{' '}
                                      <Text style={styles.cardsInfoData}>4mm</Text>
                                      {'\n'}
                                      <Text style={styles.cardsInfo}>Video Support:</Text>{' '}
                                      <Text style={styles.cardsInfoData}>2K HD, 4K UHD</Text>
                                      {'\n'}
                                      <Text style={styles.cardsInfo}>Contrast Ratio:</Text>{' '}
                                      <Text style={styles.cardsInfoData}>3000 : 1</Text>
                                      {'\n'}
                                      <Text style={styles.cardsInfo}>Refresh Rate:</Text>{' '}
                                      <Text style={styles.cardsInfoData}>1920Hz</Text>
                                      {'\n'}
                                      <Text style={styles.cardsInfo}>Module Resolution:</Text>{' '}
                                      <Text style={styles.cardsInfoData}>80*40=3200 Pixels</Text>
                                      {'\n'}
                                      <Text style={styles.cardsInfo}>Beam Angle (Hor°/Ver°):</Text>{' '}
                                      <Text style={styles.cardsInfoData}>140°/140°</Text>
                                      {'\n'}
                                    </Text>
                                  ))}
                                {/* P 5 Specifications */}
                                {details.module === 'P 5' ||
                                  (details.module === 'P5' && (
                                    <Text>
                                      <Text style={styles.cardsInfo}>LED Type:</Text>{' '}
                                      <Text style={styles.cardsInfoData}>SMD1921</Text>
                                      {'\n'}
                                      <Text style={styles.cardsInfo}>Pixel Pitch:</Text>{' '}
                                      <Text style={styles.cardsInfoData}>5mm</Text>
                                      {'\n'}
                                      <Text style={styles.cardsInfo}>Video Support:</Text>{' '}
                                      <Text style={styles.cardsInfoData}>2K HD, 4K UHD</Text>
                                      {'\n'}
                                      <Text style={styles.cardsInfo}>Contrast Ratio:</Text>{' '}
                                      <Text style={styles.cardsInfoData}>3000 : 1</Text>
                                      {'\n'}
                                      <Text style={styles.cardsInfo}>Refresh Rate:</Text>{' '}
                                      <Text style={styles.cardsInfoData}>1920Hz</Text>
                                      {'\n'}
                                      <Text style={styles.cardsInfo}>Module Resolution:</Text>{' '}
                                      <Text style={styles.cardsInfoData}>64*32=2048 Pixels</Text>
                                      {'\n'}
                                      <Text style={styles.cardsInfo}>Beam Angle (Hor°/Ver°):</Text>{' '}
                                      <Text style={styles.cardsInfoData}>140°/140°</Text>
                                      {'\n'}
                                    </Text>
                                  ))}
                                {/* P 8 Specifications */}
                                {details.module === 'P 8' ||
                                  (details.module === 'P8' && (
                                    <Text>
                                      <Text style={styles.cardsInfo}>LED Type:</Text>{' '}
                                      <Text style={styles.cardsInfoData}>SMD2727</Text>
                                      {'\n'}
                                      <Text style={styles.cardsInfo}>Pixel Pitch:</Text>{' '}
                                      <Text style={styles.cardsInfoData}>8mm</Text>
                                      {'\n'}
                                      <Text style={styles.cardsInfo}>Video Support:</Text>{' '}
                                      <Text style={styles.cardsInfoData}>2K HD, 4K UHD</Text>
                                      {'\n'}
                                      <Text style={styles.cardsInfo}>Contrast Ratio:</Text>{' '}
                                      <Text style={styles.cardsInfoData}>3000 : 1</Text>
                                      {'\n'}
                                      <Text style={styles.cardsInfo}>Refresh Rate:</Text>{' '}
                                      <Text style={styles.cardsInfoData}>1920Hz</Text>
                                      {'\n'}
                                      <Text style={styles.cardsInfo}>Module Resolution:</Text>{' '}
                                      <Text style={styles.cardsInfoData}>40*20=800 Pixels</Text>
                                      {'\n'}
                                      <Text style={styles.cardsInfo}>Beam Angle (Hor°/Ver°):</Text>{' '}
                                      <Text style={styles.cardsInfoData}>140°/140°</Text>
                                      {'\n'}
                                    </Text>
                                  ))}
                                {/* ------------------------------------------------------------------------------ */}
                                <Text style={styles.cardsInfo}>Receiving Card:</Text>{' '}
                                <Text style={styles.cardsInfoData}>{details.cardInfo}</Text>
                                {'\n'}
                                <Text style={styles.cardsInfo}>Video Processor:</Text>{' '}
                                <Text style={styles.cardsInfoData}>{details.videoProcessor}</Text>
                                {'\n'}
                                <Text style={styles.cardsInfo}>Video Processor Description:</Text>{' '}
                                <Text style={styles.cardsInfoData}>{details.videoProcessorDescription}</Text>
                                {'\n'}
                                {/* ------------------------------------------------------------------------------ */}
                                <Text style={styles.serviceAmount}>Actual Size:</Text>{' '}
                                <Text
                                  style={{
                                    fontSize: '11px',
                                    width: '40%',
                                    color: 'red',
                                    fontFamily: 'KadwaBold',
                                    paddingLeft: 10,
                                  }}
                                >
                                  {details.screenWidth} Ft (W)
                                </Text>{' '}
                                by{' '}
                                <Text
                                  style={{
                                    fontSize: '11px',
                                    width: '40%',
                                    color: 'red',
                                    fontFamily: 'KadwaBold',
                                    paddingLeft: 10,
                                  }}
                                >
                                  {details.screenHeight} Ft (H)
                                </Text>
                              </Text>
                            </Text>
                          </View>
                          <View style={[styles.descriptionBox2, { textAlign: 'center' }]}>
                            <Text style={styles.ribbonLabel2}>
                              {(details.screenWidth * details.screenHeight).toFixed(2)}
                            </Text>
                          </View>
                          <View style={[styles.descriptionBox2, { textAlign: 'center' }]}>
                            <Text style={styles.ribbonLabel2}>{details.noOfScreen}</Text>
                          </View>
                          <View style={[styles.descriptionBox2, { textAlign: 'center' }]}>
                            <Text style={styles.ribbonLabel2}>
                              {numberWithCommas(parseInt(details.rate, 10).toFixed(0))}
                            </Text>
                          </View>
                          <View style={[styles.descriptionBox2, { textAlign: 'center' }]}>
                            <Text style={styles.ribbonLabel2}>
                              {' '}
                              {numberWithCommas(
                                parseInt(details.screenWidth * details.screenHeight * details.rate, 10)
                              )}
                            </Text>
                          </View>
                          <View style={[styles.descriptionBox2, { textAlign: 'center' }]}>
                            <Text style={styles.ribbonLabel2}>
                              {' '}
                              {numberWithCommas(
                                (
                                  parseInt(details.screenWidth * details.screenHeight * details.rate, 10) *
                                  details.noOfScreen
                                ).toFixed(2)
                              )}
                            </Text>
                          </View>
                        </View>

                        {/* GST and other Pricing Values */}
                        {/* <View style={styles.pricing}>
                          <View style={styles.pricingBox1}>
                            <Text style={{ paddingRight: 10 }}>Iron Structure for Screen with labour.</Text>
                          </View>

                          <View style={styles.pricingBox2}>
                            <Text style={styles.ribbonLabel}>1,00,000</Text>
                          </View>
                        </View> */}

                        {/* ------------------------------------------------------------------------ */}
                        {/* <View style={styles.pricing}>
                          <View style={styles.pricingBox1}>
                            <Text style={{ paddingRight: 10 }}>Labour of Screen Installation, Configuration & Transportation.</Text>
                          </View>

                          <View style={styles.pricingBox2}>
                            <Text style={styles.ribbonLabel}>
                              {numberWithCommas(parseInt(details.screenLabour, 10).toFixed(2))} /- PKR
                            </Text>
                          </View>
                        </View> */}
                        {/* ------------------------------------------------------------------------ */}
                        {details.gst === 'yes' ? (
                          <View style={styles.pricing}>
                            <View style={styles.pricingBox1}>
                              <Text style={{ paddingRight: 10 }}>GST</Text>
                            </View>

                            <View style={styles.pricingBox2}>
                              <Text style={styles.ribbonLabel}>
                                <Text>
                                  <Text>
                                    {numberWithCommas(
                                      (
                                        ((details.noOfScreen *
                                          (details.screenWidth * details.screenHeight * details.rate)) /
                                          100) *
                                        18
                                      ).toFixed(2)
                                    )}
                                    {' /- PKR '}
                                  </Text>
                                </Text>
                              </Text>
                            </View>
                          </View>
                        ) : null}
                        {/* ------------------------------------------------------------------------ */}
                        <View style={styles.pricing}>
                          <View style={{ textAlign: 'left' }}>
                            <Text style={styles.summaryMeta}>Valid Till Date: {dueDate.toLocaleDateString()}</Text>
                          </View>
                          <View style={styles.pricingBox3}>
                            <Text style={{ paddingRight: 10 }}>Total Amount</Text>
                          </View>

                          <View style={styles.pricingBox4}>
                            <Text style={styles.ribbonLabel}>
                              {details.gst === 'yes' ? (
                                <Text>
                                  <Text>
                                    {numberWithCommas(
                                      parseInt(
                                        parseInt(details.screenWidth * details.screenHeight * details.rate, 10) *
                                          details.noOfScreen +
                                          // parseInt(details.screenLabour, 10) +
                                          ((parseInt(details.screenWidth * details.screenHeight * details.rate, 10) *
                                            details.noOfScreen) /
                                            100) *
                                            18,
                                        10
                                      ).toFixed(2)
                                    )}
                                    {' /- PKR '}
                                  </Text>
                                </Text>
                              ) : (
                                <Text>
                                  {numberWithCommas(
                                    parseInt(
                                      parseInt(details.screenWidth * details.screenHeight * details.rate, 10) *
                                        details.noOfScreen,
                                      10
                                    ) + parseInt(details.screenLabour, 10)
                                  )}
                                  {' /- PKR '}
                                </Text>
                              )}
                            </Text>
                          </View>
                        </View>

                        {/* Terms & Conditions */}
                        {/* ------------------------------------------------------------------------ */}
                        <View style={styles.ribbonBlueTerms}>
                          <View style={styles.TermsConditionBox}>
                            <Text style={styles.TermsCondition}>Terms & Conditions</Text>
                          </View>
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                          <View style={{ width: '60%', marginTop: 10 }}>
                            <Text style={styles.TermsConditionLine}>
                              <Text style={styles.TermsConditionLineHeading}>Payment:</Text> {details.payment}
                            </Text>
                            <Text style={styles.TermsConditionLine}>
                              <Text style={styles.TermsConditionLineHeading}>Service Warranty:</Text> {details.warranty}
                            </Text>
                            <Text style={styles.TermsConditionLine}>
                              <Text style={styles.TermsConditionLineHeading}>Delivery Time:</Text> {details.validity}
                            </Text>
                            <Text style={styles.TermsConditionLine}>
                              <Text style={styles.TermsConditionLineHeading}>Power Stability:</Text> Stabilised Power is
                              the responsibility of Client.
                            </Text>
                          </View>
                          <View style={{ width: '40%', marginTop: 10 }}>
                            <Image style={{ height: 230 }} src={billboard} />
                          </View>
                        </View>

                        {/* Our Clients */}
                        {/* ------------------------------------------------------------------------ */}
                        {/* <View style={styles.ribbonBlueTerms}>
                          <View style={styles.TermsConditionBox}>
                            <Text style={styles.TermsCondition}>Our Clients</Text>
                          </View>
                        </View>
                        {/* <View>
                          <Image style={styles.clientsImages} src={embassy} />
                        </View> */}
                        {/* <View style={styles.blueRibbonValues}>
                          <View style={styles.ribbonBox}>
                            <Image style={styles.clientsImages} src={embassy} />
                          </View>
                          <View style={styles.ribbonBox}>
                            <Image style={styles.clientsImages} src={embassy} />
                          </View>
                          <View style={styles.ribbonBox}>
                            <Image style={styles.clientsImages} src={embassy} />
                          </View>
                          <View style={styles.ribbonBox}>
                            <Image style={styles.clientsImages} src={embassy} />
                          </View>
                          <View style={styles.ribbonBox}>
                            <Image style={styles.clientsImages} src={embassy} />
                          </View>
                        </View>  */}
                        {/* Footer */}
                        {/* ------------------------------------------------------------------------ */}
                        <View style={styles.footer}>
                          <Text>
                            Office No. 318, Hamdan Heights, Islamabad Expressway, Islamabad.
                            {'\n'}
                            info@aiodock.com &bull; +92 318 7745768
                          </Text>
                        </View>
                        <Watermark />
                      </Page>
                    </Document>
                  </PDFViewer>
                </Typography>
              </Box>
            </Modal>
          </Stack>
        </LocalizationProvider>
      </Container>
    </>
  );
}
function Watermark() {
  const styles = StyleSheet.create({
    body: {
      // Your existing styles for the page
    },
    watermark: {
      position: 'absolute',
      top: '25%', // Adjust the vertical positioning
      left: '25%', // Adjust the horizontal positioning
      transform: 'translate(-50%, -50%)', // Center the watermark
    },
    watermarkImage: {
      width: '400px', // Adjust the image width
      height: '400px', // Adjust the image height
      opacity: 0.1, // Adjust the image opacity
    },

    // Your existing styles for other components
  });

  return (
    <View style={styles.watermark}>
      <Image style={styles.watermarkImage} src={watermark} />
    </View>
  );
}
