import * as React from 'react';
import { useState, useEffect } from 'react';
// date and time
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// @mui
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Autocomplete,
  Stack,
  Button,
  CssBaseline,
  Container,
  Typography,
  TextField,
  Modal,
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputAdornment,
  OutlinedInput,
  Snackbar,
  Alert,
  Divider,
  DialogTitle,
  Dialog,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
// components
import { PDFViewer } from '@react-pdf/renderer';
import PDF from '../../PDF/main';
import Iconify from '../../components/iconify';

export default function Qoutation(props) {
  const [date, setDate] = React.useState(new Date());
  const [module, setModule] = React.useState('');
  const [GST, setGST] = React.useState('');
  const [cabinet, setCabinet] = React.useState('');
  const [category, setCategory] = React.useState('');
  // const [rate, setRate] = React.useState(0);
  const [quotation, setQuotation] = React.useState(false);
  const [details, setDetails] = React.useState({});
  const [formData, setFormData] = useState({
    surName: '',
    screenLabour: 0,
    videoProcessor: '',
    videoProcessorDescription: '',
    cardInfo: '',
    PON: '',
    clientCompany: '',
    clientAddress: '',
    rate: 0,
    clientId: '',
    screenWidth: 0,
    screenHeight: 0,
    noOfScreen: '',
    sendingCardInfo: '',
    receivingCardInfo: '',
    cabinet: '',
    cabinetInfo: '',
    gst: '',
    // gstPercentage: 0,
    module: '',
    category: '',
    discount: 0,
    brand: '',
    // signByName: '',
    // signByDesignation: '',
    // signByCompanyName: '',
    payment: '100% Advance/Issuance of Purchase Order.',
    validity: '10 Days after Payment, subject to availablitiy of Stock.',
    warranty: '01 Year after date of delivery.',
    date: '',
    status: 'Sent / WIP',
    remarks: '',
    frame: '',
    modulesInWidth: '',
    modulesInHeight: '',
    ntn: '',
    stn: '',
  });

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };
  // date n timne
  const [value, setValue] = React.useState(dayjs('2014-08-18T21:11:54'));
  const [leaveFrom, setLeaveFrom] = React.useState(dayjs('2014-08-18T21:11:54'));
  const [leaveTo, setLeaveTo] = React.useState(dayjs('2014-08-18T21:11:54'));
  const [result, setResult] = useState(null);
  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const theme = createTheme();

  // ---------------------Modal-------------------------------
  const [openModal, setOpenModal] = useState(true);
  //   const [open, setOpen] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [moduleSnackbar, setModuleSnackbar] = useState(false);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    height: '100vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    px: 4,
  };

  // Form validity status
  const [isFormValid, setFormValid] = useState(true);

  // Submit Data to the Database
  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if the form is valid
    // if (!e.target.checkValidity()) {
    //   setFormValid(false);
    //   return; // Don't submit if the form is invalid
    // }
    // setFormValid(true);
    formData.date = date.toISOString();
    // get token from local storage
    const storedToken = localStorage.getItem('userToken');

    // check if token then parse into json and use it
    const token = storedToken ? JSON.parse(storedToken) : null;

    if (token) {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };
      fetch('https://quotationserver.aiodock.com/addQuotation', {
        method: 'POST',
        headers,
        body: JSON.stringify(formData),
      })
        .then((response) => response.json())
        .then((data) => {
          setResult(data);
          setFormData({
            PON: '',
            date: '',
            rate: 0,
            clientId: '',
            clientName: '',
            screenWidth: 0,
            screenHeight: 0,
            noOfScreen: '',
            sendingCardInfo: '',
            receivingCardInfo: '',
            cabinet: 'no',
            cabinetInfo: '',
            gst: 'no',
            // gstPercentage: 0,
            module: '',
            category: '',
            brand: '',
            discount: 0,
            // signByName: '',
            // signByDesignation: '',
            // signByCompanyName: '',
            payment: '',
            validity: '',
            warranty: '',
            status: '',
            remarks: '',
            frame: '',
            modulesInWidth: '',
            modulesInHeight: '',
            surName: '',
            screenLabour: 0,
            videoProcessor: '',
            videoProcessorDescription: '',
            cardInfo: '',
            ntn: '',
            stn: '',
          });

          setOpenSnackbar(true);
        })
        .catch(() => {
          setResult({
            success: false,
            message: 'Something went wrong. Try again later',
          });
        });
    } else {
      alert('token not found to fullfill this request');
    }
  };

  useEffect(() => {
    if (openSnackbar) {
      const snackbarTimer = setTimeout(() => {
        props.onClose();
      }, 1500);

      return () => {
        clearTimeout(snackbarTimer);
      };
    }
    return undefined;
  }, [openSnackbar]);

  const [gstSelected, setGstSelected] = useState(false);
  const [cabinetInformation, setCabinetInformation] = useState(false);
  const handleChangeData = (event) => {
    const { name, value } = event.target;
    setFormData((formData) => ({
      ...formData,
      [name]: value,
    }));
    if (name === 'gst') {
      setGstSelected(value === 'yes');
    }
    if (name === 'cabinet') {
      setCabinetInformation(value === 'yes');
    }
  };

  const handleClientSelect = (event, value) => {
    if (value) {
      setFormData((formData) => ({
        ...formData,
        clientId: value._id,
        clientName: value.clientName,
        clientAddress: value.clientAddress,
        clientCompany: value.company,
        contactNo: value.contactNo,
        clientCity: value.city,
      }));
      setSelectedClient(value);
    }
  };
  const [clients, setClients] = useState([]);
  useEffect(() => {
    // get token from local storage
    const storedToken = localStorage.getItem('userToken');

    // check if token then parse into json and use it
    const token = storedToken ? JSON.parse(storedToken) : null;

    if (token) {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };
      fetch('https://quotationserver.aiodock.com/showClients', {
        method: 'GET',
        headers,
      }).then((result) => {
        result.json().then((response) => {
          setClients(response.message);
        });
      });
    } else {
      alert('token not found to fullfill this request');
    }
  }, []);

  const [smdModule, setSmdModule] = useState([]);
  useEffect(() => {
    // get token from local storage
    const storedToken = localStorage.getItem('userToken');

    // check if token then parse into json and use it
    const token = storedToken ? JSON.parse(storedToken) : null;

    if (token) {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };
      fetch('https://quotationserver.aiodock.com/showModule', {
        method: 'GET',
        headers,
      }).then((result) => {
        result.json().then((response) => {
          setSmdModule(response.message);
        });
      });
    } else {
      alert('token not found to fullfill this request');
    }
  }, []);

  const uniqueModules = [...new Set(smdModule.map((module) => module.moduleName))];
  const uniqueCategories = [...new Set(smdModule.map((category) => category.moduleCategory))];
  const uniqueBrand = [...new Set(smdModule.map((brand) => brand.moduleBrand))];

  const handleModuleSelect = (event, value) => {
    if (value) {
      setFormData((formData) => ({
        ...formData,
        module: value,
      }));
      setSelectedModule(value);
    }
  };

  const handleCategorySelect = (event, value) => {
    if (value) {
      setFormData((formData) => ({
        ...formData,
        category: value,
      }));
      setSelectedCategory(value);
    }
  };

  const handleBrandSelect = (event, value) => {
    if (value) {
      setFormData((formData) => ({
        ...formData,
        brand: value,
      }));
      setSelectedBrand(value);
    }
  };

  const handlePriceButtonClick = () => {
    const selectedModule1 = document.getElementById('moduleSelect').value;
    const selectedCategory1 = document.getElementById('categorySelect').value;
    const selectedBrand1 = document.getElementById('brandSelect').value;

    if (selectedModule1 && selectedCategory1 && selectedBrand1) {
      // get token from local storage
      const storedToken = localStorage.getItem('userToken');

      // check if token then parse into json and use it
      const token = storedToken ? JSON.parse(storedToken) : null;

      if (token) {
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        };
        fetch('https://quotationserver.aiodock.com/showModule', {
          method: 'GET',
          headers,
        })
          .then((result) => result.json())
          .then((response) => {
            const module = response.message.find(
              (item) =>
                item.moduleName === selectedModule1 &&
                item.moduleCategory === selectedCategory1 &&
                item.moduleBrand === selectedBrand1
            );

            if (module) {
              const price = module.moduleRate;
              setFormData((formData) => ({
                ...formData,
                rate: price,
              }));
            } else {
              setModuleSnackbar(true);
            }
          })
          .catch((error) => {
            console.error('Error fetching module data:', error);
          });
      }
      // else for not token
      else {
        alert('token not found to fullfill this request');
      }
    } else {
      console.error('Invalid module and category selected.');
    }
  };

  // -->> For Iron Structure
  const handleModulesWidthChange = (event) => {
    const numberOfModulesInWidth = event.target.value;
    const selectedCategory = document.getElementById('categorySelect').value;

    // With Frame
    // Convert to Inches (width)
    let widthWithFrameInches;

    if (selectedCategory === 'OUTDOOR') {
      widthWithFrameInches = (numberOfModulesInWidth * (320 / 25.4) + 3).toFixed(2);
    } else {
      // Convert to Inches with indoor frame
      widthWithFrameInches = (numberOfModulesInWidth * (320 / 25.4) + 1.5).toFixed(2);
    }

    // Convert to Feet(width)
    const widthWithFrameFeet = (widthWithFrameInches / 12).toFixed(2);
    document.getElementById('smd-width').value = widthWithFrameFeet;

    setFormData((formData) => ({
      ...formData,
      screenWidth: widthWithFrameFeet,
      modulesInWidth: numberOfModulesInWidth,
    }));
  };

  const handleModulesHeightChange = (event) => {
    const numberOfModulesInHeight = event.target.value;
    const selectedCategory = document.getElementById('categorySelect').value;

    let heightWithFrameInches;

    if (selectedCategory === 'OUTDOOR') {
      // Convert to Inches with outdoor frame
      heightWithFrameInches = (numberOfModulesInHeight * (160 / 25.4) + 3).toFixed(2);
    } else {
      // Convert to Inches with indoor frame
      heightWithFrameInches = (numberOfModulesInHeight * (160 / 25.4) + 1.5).toFixed(2);
    }

    // Convert to Feet (height)
    const heightWithFrameFeet = (heightWithFrameInches / 12).toFixed(2);

    document.getElementById('smd-height').value = heightWithFrameFeet;
    setFormData((formData) => ({
      ...formData,
      screenHeight: heightWithFrameFeet,
      modulesInHeight: numberOfModulesInHeight,
    }));
  };

  // -->> For Cabinet Structure
  const handleCabinetWidthChange = (event) => {
    const numberOfCabinetsInWidth = event.target.value;
    const selectedCategory = document.getElementById('categorySelect').value;

    // With Frame
    // Convert to Inches (width)
    let widthWithFrameInches;

    if (selectedCategory === 'OUTDOOR') {
      widthWithFrameInches = numberOfCabinetsInWidth * (960 / 25.4).toFixed(2);
    } else {
      // Convert to Inches with indoor frame
      widthWithFrameInches = numberOfCabinetsInWidth * (640 / 25.4).toFixed(2);
    }

    // Convert to Feet(width)
    const widthWithFrameFeet = (widthWithFrameInches / 12).toFixed(2);
    document.getElementById('cabinet-width').value = widthWithFrameFeet;

    setFormData((formData) => ({
      ...formData,
      screenWidth: widthWithFrameFeet,
      modulesInWidth: numberOfCabinetsInWidth,
    }));
  };

  const handleCabinetHeightChange = (event) => {
    const numberOfCabinetsInHeight = event.target.value;
    const selectedCategory = document.getElementById('categorySelect').value;

    let heightWithFrameInches;

    if (selectedCategory === 'OUTDOOR') {
      // Convert to Inches with outdoor frame
      heightWithFrameInches = numberOfCabinetsInHeight * (960 / 25.4).toFixed(2);
    } else {
      // Convert to Inches with indoor frame
      heightWithFrameInches = numberOfCabinetsInHeight * (480 / 25.4).toFixed(2);
    }

    // Convert to Feet (height)
    const heightWithFrameFeet = (heightWithFrameInches / 12).toFixed(2);

    document.getElementById('cabinet-height').value = heightWithFrameFeet;
    setFormData((formData) => ({
      ...formData,
      screenHeight: heightWithFrameFeet,
      modulesInHeight: numberOfCabinetsInHeight,
    }));
  };

  return (
    <>
      <Dialog fullScreen open={openModal} onClose={props.onClose}>
        <Box component="form" onSubmit={handleSubmit} noValidate style={{ width: '100%' }}>
          <div style={{ position: 'sticky', top: 0 }}>
            <AppBar sx={{ position: 'relative' }}>
              <Toolbar>
                <IconButton edge="start" color="inherit" onClick={props.onClose} aria-label="close">
                  <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                  Create Quotation
                </Typography>
                <Button type="submit" variant="outlined" color="inherit" sx={{ borderColor: '#fff' }}>
                  Create
                </Button>
              </Toolbar>
            </AppBar>
          </div>

          <Container>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <ThemeProvider theme={theme}>
                <CssBaseline />

                {/* <DialogTitle id="customized-dialog-title" sx={{ textAlign: 'center' }}>
                QUOTATION
              </DialogTitle> */}
                <Divider fullWidth />
                {!isFormValid && (
                  <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                    Please Enter all Information.
                  </Typography>
                )}
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={2}
                    // style={{
                    //   marginTop: 5,
                    // }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat="dd/MM/yyyy"
                        value={date}
                        // label={'Date'}
                        onChange={(date) => {
                          setDate(date);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} margin="normal" label={'Date'} variant="outlined" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <FormControl fullWidth>
                      <TextField
                        variant="filled"
                        margin="normal"
                        select
                        label="Title"
                        name="surName"
                        value={formData.surName}
                        onChange={handleChangeData}
                        autoFocus
                      >
                        <MenuItem value="Mr">Mr</MenuItem>
                        <MenuItem value="Ms">Ms</MenuItem>
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Autocomplete
                      id="client-autocomplete"
                      options={clients}
                      getOptionLabel={(client) => `${client.clientName} || ${client.company}`}
                      onChange={handleClientSelect}
                      value={selectedClient}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Client Business Name"
                          margin="normal"
                          required
                          type="text"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="noOfScreen"
                      value={formData.noOfScreen}
                      onChange={handleChangeData}
                      label="No. of Screens"
                      type="number"
                      id="screen"
                      autoComplete="current-address"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <FormControl fullWidth>
                      <TextField
                        variant="filled"
                        margin="normal"
                        select
                        label="Frame Structure"
                        name="frame"
                        value={formData.frame}
                        onChange={handleChangeData}
                        autoFocus
                      >
                        <MenuItem value="" disabled>
                          Select Frame
                        </MenuItem>
                        <MenuItem value="Iron">Iron</MenuItem>
                        <MenuItem value="Cabinet">Cabinet</MenuItem>
                      </TextField>
                    </FormControl>
                  </Grid>

                  {formData.frame === 'Iron' && (
                    <>
                      <Grid item xs={12} sm={6} md={2}>
                        <Autocomplete
                          id="categorySelect"
                          options={uniqueCategories}
                          getOptionLabel={(category) => category}
                          onChange={handleCategorySelect}
                          value={selectedCategory}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Category"
                              margin="normal"
                              required
                              type="text"
                              variant="filled"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={2}>
                        <TextField
                          margin="normal"
                          required
                          name="width"
                          label="Modules in Width"
                          type="text"
                          // value={formData.modulesInWidth}
                          onChange={handleModulesWidthChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={2}>
                        <TextField
                          margin="normal"
                          required
                          name="height"
                          label="Modules in Height"
                          type="text"
                          // value={formData.modulesInHeight}
                          onChange={handleModulesHeightChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={2}>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          // name="screenWidth"
                          // value={formData.screenWidth}
                          // onChange={handleChangeData}
                          label="Width (Feets)"
                          type="number"
                          id="smd-width"
                          defaultValue="0"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={2}>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          defaultValue="0"
                          // name="screenHeight"
                          // value={formData.screenHeight}
                          // onChange={handleChangeData}
                          label="Height (Feets)"
                          type="number"
                          id="smd-height"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={2} />
                    </>
                  )}
                  {formData.frame === 'Cabinet' && (
                    <>
                      <Grid item xs={12} sm={6} md={2}>
                        <Autocomplete
                          id="categorySelect"
                          options={uniqueCategories}
                          getOptionLabel={(category) => category}
                          onChange={handleCategorySelect}
                          value={selectedCategory}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Category"
                              margin="normal"
                              required
                              type="text"
                              variant="filled"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={2}>
                        <TextField
                          margin="normal"
                          required
                          name="width"
                          label="Cabinets in Width"
                          type="text"
                          // value={formData.modulesInWidth}
                          onChange={handleCabinetWidthChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={2}>
                        <TextField
                          margin="normal"
                          required
                          name="height"
                          label="Cabinets in Height"
                          type="text"
                          // value={formData.modulesInHeight}
                          onChange={handleCabinetHeightChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={2}>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          // name="screenWidth"
                          // value={formData.screenWidth}
                          // onChange={handleChangeData}
                          label="Width (Feets)"
                          type="number"
                          id="cabinet-width"
                          defaultValue="0"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={2}>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          defaultValue="0"
                          // name="screenHeight"
                          // value={formData.screenHeight}
                          // onChange={handleChangeData}
                          label="Height (Feets)"
                          type="number"
                          id="cabinet-height"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={2} />
                    </>
                  )}

                  <Grid item xs={12} sm={6} md={2}>
                    <Autocomplete
                      id="moduleSelect"
                      options={uniqueModules}
                      getOptionLabel={(module) => module}
                      onChange={handleModuleSelect}
                      value={selectedModule}
                      renderInput={(params) => (
                        <TextField {...params} label="Module" margin="normal" required type="text" variant="filled" />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={2}>
                    <Autocomplete
                      id="brandSelect"
                      options={uniqueBrand}
                      getOptionLabel={(brand) => brand}
                      onChange={handleBrandSelect}
                      value={selectedBrand}
                      name="brand"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Brand"
                          margin="normal"
                          name="brand"
                          required
                          type="text"
                          variant="filled"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={2}>
                    <Button
                      color="success"
                      size="sm"
                      variant="contained"
                      style={{ marginTop: 23 }}
                      onClick={handlePriceButtonClick}
                      fullWidth
                    >
                      Add Price
                    </Button>
                  </Grid>

                  <Grid item xs={12} sm={6} md={2}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      value={formData.rate}
                      onChange={handleChangeData}
                      name="rate"
                      label={`Rate Per ft² (${numberWithCommas(formData.rate)})`}
                      type="number"
                      id="rate"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="discount"
                      value={formData.discount}
                      onChange={handleChangeData}
                      label="Discount Per ft²"
                      type="number"
                      id="discount"
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={6} md={2}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="screenLabour"
                      value={formData.screenLabour}
                      onChange={handleChangeData}
                      label="Screen Labour"
                      type="number"
                    />
                  </Grid> */}
                  <Grid item xs={12} sm={6} md={2}>
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">GST</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="gst"
                        onChange={handleChangeData}
                      >
                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="no" control={<Radio />} label="No" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  {/* <Grid item xs={12} sm={4} md={4}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="sendingCardInfo"
                      onChange={handleChangeData}
                      value={formData.sendingCardInfo}
                      label="Sending Card Information"
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      value={formData.receivingCardInfo}
                      onChange={handleChangeData}
                      name="receivingCardInfo"
                      label="Receiving Card Information"
                      type="text"
                    />
                  </Grid> */}

                  {/* <Grid item={5}>
                          <FormControl sx={{ marginTop: 2 }}>
                            <InputLabel htmlFor="outlined-adornment-amount">GST Percentage</InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-amount"
                              startAdornment={<InputAdornment position="start">Rs.</InputAdornment>}
                              aria-describedby="standard-amount-helper-text"
                              inputProps={{
                                'aria-label': 'amount',
                              }}
                              margin="normal"
                              fullWidth
                              style={{ width: '22em', marginRight: 6 }}
                              name="gstPercentage"
                              value={formData.gstPercentage}
                              onChange={handleChangeData}
                              label="GST Percentage"
                              type="number"
                              required={gstSelected} // only required when GST is selected
                              disabled={!gstSelected} // only enable when GST is selected
                            />
                          </FormControl>
                        </Grid> */}

                  {/* <Grid item={2}>
                          <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">Cabinet</FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="cabinet"
                              onChange={handleChangeData}
                            >
                              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                              <FormControlLabel value="no" control={<Radio />} label="No" />
                            </RadioGroup>
                          </FormControl>
                        </Grid>

                        <Grid item={2}>
                          <TextField
                            margin="normal"
                            fullWidth
                            style={{ width: '24em', paddingRight: 8 }}
                            name="cabinetInfo"
                            value={formData.cabinetInfo}
                            onChange={handleChangeData}
                            label="Cabinet Information"
                            type="text"
                            required={cabinetInformation}
                            disabled={!cabinetInformation}
                          />
                        </Grid> */}

                  {/* <Grid item={2}>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            style={{ width: '22em', paddingRight: 7 }}
                            name="signByName"
                            value={formData.signByName}
                            onChange={handleChangeData}
                            label="Sign By Name"
                            type="text"
                          />
                        </Grid>
                        <Grid item={2}>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            style={{ width: '22em', paddingRight: 7 }}
                            name="signByDesignation"
                            value={formData.signByDesignation}
                            onChange={handleChangeData}
                            label="Sign By Designation"
                            type="text"
                          />
                        </Grid>
                        <Grid item={2}>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            style={{ width: '21em' }}
                            name="signByCompanyName"
                            onChange={handleChangeData}
                            value={formData.signByCompanyName}
                            label="Sign By Comapany Name"
                            type="text"
                          />
                        </Grid> */}

                  <Grid item xs={12} sm={2} md={2}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="PON"
                      value={formData.PON}
                      onChange={handleChangeData}
                      label="PO No."
                      type="text"
                    />
                  </Grid>

                  <Grid item xs={12} sm={2} md={2}>
                    <FormControl fullWidth>
                      <TextField
                        variant="filled"
                        margin="normal"
                        select
                        label="Status"
                        name="status"
                        value={formData.status}
                        onChange={handleChangeData}
                        autoFocus
                      >
                        <MenuItem value="" disabled>
                          Select Status
                        </MenuItem>
                        <MenuItem value="Sent / WIP">Sent / WIP</MenuItem>
                        <MenuItem value="Win">Win</MenuItem>
                        <MenuItem value="Loss">Loss</MenuItem>
                        <MenuItem value="Contact Later">Contact Later</MenuItem>
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="cardInfo"
                      onChange={handleChangeData}
                      value={formData.cardInfo}
                      label="Card Information"
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="videoProcessor"
                      onChange={handleChangeData}
                      value={formData.videoProcessor}
                      label="Video Processor"
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="ntn"
                      onChange={handleChangeData}
                      value={formData.ntn}
                      label="NTN No."
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="stn"
                      onChange={handleChangeData}
                      value={formData.stn}
                      label="STN No."
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="videoProcessorDescription"
                      onChange={handleChangeData}
                      value={formData.videoProcessorDescription}
                      label="Video Processor Description"
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    {' '}
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="remarks"
                      value={formData.remarks}
                      onChange={handleChangeData}
                      label="Remarks"
                      type="text"
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={4}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="payment"
                      value={formData.payment}
                      onChange={handleChangeData}
                      label="Payment"
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="validity"
                      value={formData.validity}
                      onChange={handleChangeData}
                      label="Delivery Time"
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="warranty"
                      value={formData.warranty}
                      onChange={handleChangeData}
                      label="Service Warranty"
                      type="text"
                    />
                  </Grid>

                  {/* <Grid
                    item
                    
                    xs={12}
                    sm={6}
                    md={6}
                  />
                  <Grid
                    item
                    
                    xs={12}
                    sm={6}
                    md={12}
                  >
                    <Box sx={{ display: 'flex' }}>
                      <Button type="submit" color="secondary" variant="contained" sx={{ mb: 2, width: '10%' }}>
                        Create
                      </Button>
                      <Button onClick={props.onClose} variant="outlined" sx={{ mb: 2, mx: 3, width: '10%' }}>
                        Cancel
                      </Button>
                    </Box>
                  </Grid> */}
                </Grid>
              </ThemeProvider>
              <Snackbar
                open={openSnackbar}
                autoHideDuration={1500}
                onClose={() => setOpenSnackbar(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <Alert variant="filled" severity="success" sx={{ width: '100%' }}>
                  Quotation Created Succesfully!
                </Alert>
              </Snackbar>
              <Snackbar
                open={moduleSnackbar}
                autoHideDuration={1500}
                onClose={() => setModuleSnackbar(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <Alert variant="filled" severity="error" sx={{ width: '100%' }}>
                  Module Not Exists. <br />
                  Please Change its Category or Brand.
                </Alert>
              </Snackbar>
            </LocalizationProvider>
          </Container>
        </Box>
      </Dialog>
    </>
  );
}
